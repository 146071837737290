import { createContext, useContext, useState } from 'react';
import { appRoot, getServerCode, setLogin, setNext, setServerCode, setToken } from '../Api/Tools/Api';
import * as Constants from '../Tools/Constants';
import { setUserData } from '../Redux/User/actions';
import { useDispatch } from 'react-redux';
import LocalStorage from '../Tools/LocalStorage';
import { APP_PATH, AUTH_PATH } from '../Tools/Constants';
import { setCurrentBot } from '../Redux/Bot/actions';
import { useNavigate } from 'react-router-dom';

export interface UserContextProps {
  logout: () => void;
  login: string;
  setLogin: (user: string) => void;
  nextUrl: string;
  encodedNextUrl: string;
  currentNavigationStep: number;
  setCurrentNavigationStep?: (step: number) => void;
}

export const UserContext = createContext({} as UserContextProps);

export const useUserContext = () => useContext<UserContextProps>(UserContext);

enum QueryParams {
  id = 'id',
  host = 'host',
  login = 'login',
  botUUID = 'botUUID',
  lang = 'lang',
  proxy = 'proxy',
  next = 'next',
}

const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState<string>('');
  const [currentNavigationStep, setCurrentNavigationStep] = useState<number>(0);
  const navigate = useNavigate();
  let url = window.location.href;
  console.log('userProvider run ' + window.location.href);

  function logout() {
    // to be sure warning popup will not appear before logout
    window.onbeforeunload = () => {};

    console.log('Send logout to parent !!!!!');
    window.parent.postMessage(
      {
        type: 'channels_logout',
      },
      '*'
    );
  }

  if (url.includes(AUTH_PATH)) {
    window.addEventListener('message', function (msg) {
      if (msg.data) {
        if (msg.data.type === 'provide_jwt') {
          setToken(msg.data.content);
          console.log('Token is set on url ', url + ' and href: ' + window.location.href);
          navigate(APP_PATH.AUTH); // will redirect on NEXT path
        }
      }
    });

    url = url.replace('#', '/');
    const location = new URL(url);
    const queryParameters = new URLSearchParams(location.search);

    const id = queryParameters.get(QueryParams.id);
    const host = queryParameters.get(QueryParams.host);
    const login = queryParameters.get(QueryParams.login);
    const botUUID = queryParameters.get(QueryParams.botUUID);
    const lang = queryParameters.get(QueryParams.lang);
    const proxy = queryParameters.get(QueryParams.proxy);
    const next = queryParameters.get(QueryParams.next);

    lang && LocalStorage.locale.save(lang);
    host && setServerCode(host);
    login && setLogin(login);
    botUUID && sessionStorage.setItem(Constants.ATRIA_BOTUUID, botUUID);
    const item = '{botUUID: "' + botUUID + '"}';
    dispatch(setCurrentBot(item));
    if (next && next === 'external-contents') {
      setNext(APP_PATH.EXTERNAL_CONTENTS);
    } else if (next && next === 'configurations') {
      setNext(APP_PATH.CONFIGURATIONS);
    }
    // prettier-ignore
    { console.log('id: ' + id + '\n' +
          'host: ' + getServerCode() + '\n' +
          'login: ' + login + '\n' + 'botUUID: ' + botUUID + '\n' + 'lang: ' + lang + '\n' + 'Proxy: ' + proxy + '\n');
    }

    const userData = {
      id,
      host,
      userName: login,
      atriaBotUUID: botUUID,
      lang,
      Proxy: proxy,
    };

    dispatch(setUserData(userData));
  }

  const cleanAppRoot = appRoot !== '/' ? appRoot : '';
  const nextUrl = new URL(window.location.href).origin + cleanAppRoot + AUTH_PATH;

  const encodedNextUrl = encodeURIComponent(nextUrl);

  const props: UserContextProps = {
    logout,
    login: user,
    setLogin: setUser,
    nextUrl: nextUrl,
    encodedNextUrl,
    currentNavigationStep: currentNavigationStep,
    setCurrentNavigationStep: setCurrentNavigationStep,
  };

  return <UserContext.Provider children={children} value={props} />;
};

export default UserProvider;
