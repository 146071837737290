import * as Types from '../types';

import { ReactNode } from 'react';
import { action } from 'src/Tools/Redux';

/**
 * SAGAS ACTIONS
 */

export const getConnectorConfigurationsList = {
  request: (botData) => action(Types.CONNECTOR_CONFIGURATION.GET_ALL.REQUEST, { botData }),
  success: (data) => action(Types.CONNECTOR_CONFIGURATION.GET_ALL.SUCCESS, { data }),
  failure: (error) => action(Types.CONNECTOR_CONFIGURATION.GET_ALL.FAILURE, { error }),
};

export const getConnectorConfiguration = {
  request: (configId: string, connectorType: string, token: string) =>
    action(Types.CONNECTOR_CONFIGURATION.GET_ONE.REQUEST, { configId, connectorType, token }),
  success: (data) => action(Types.CONNECTOR_CONFIGURATION.GET_ONE.SUCCESS, { data }),
  failure: (error) => action(Types.CONNECTOR_CONFIGURATION.GET_ONE.FAILURE, { error }),
};

export const createConnectorConfiguration = {
  request: (data: { botUUID: string; modifiedBy: string; name: string; type: string }) =>
    action(Types.CONNECTOR_CONFIGURATION.CREATE_ONE.REQUEST, { data }),
  success: (data) => action(Types.CONNECTOR_CONFIGURATION.CREATE_ONE.SUCCESS, { data }),
  failure: (error) => action(Types.CONNECTOR_CONFIGURATION.CREATE_ONE.FAILURE, { error }),
};

export const updateConnectorConfiguration = {
  request: (
    connectorType: string,
    config: Models.ConnectorConfiguration,
    options: { redirect?: boolean; downloadConfig?: boolean },
    token: string
  ) => action(Types.CONNECTOR_CONFIGURATION.UPDATE_ONE.REQUEST, { connectorType, config, options, token }),
  success: (data) => action(Types.CONNECTOR_CONFIGURATION.UPDATE_ONE.SUCCESS, { data }),
  failure: (error) => action(Types.CONNECTOR_CONFIGURATION.UPDATE_ONE.FAILURE, { error }),
};

export const updateConnectorConfigurationName = {
  request: (name: string, configId: string) =>
    action(Types.CONNECTOR_CONFIGURATION.UPDATE_NAME.REQUEST, { name, configId }),
  success: (data) => action(Types.CONNECTOR_CONFIGURATION.UPDATE_NAME.SUCCESS, { data }),
  failure: (error) => action(Types.CONNECTOR_CONFIGURATION.UPDATE_NAME.FAILURE, { error }),
};

export const deleteConnectorConfiguration = {
  request: (configId: string, connectorType: string) =>
    action(Types.CONNECTOR_CONFIGURATION.DELETE_ONE.REQUEST, { configId, connectorType }),
  success: (configId: string) => action(Types.CONNECTOR_CONFIGURATION.DELETE_ONE.SUCCESS, { configId }),
  failure: (error) => action(Types.CONNECTOR_CONFIGURATION.DELETE_ONE.FAILURE, { error }),
};

export const uploadConnectorAvatar = {
  request: (file: File, id: string, avatarType: string, connectorType: string) =>
    action(Types.CONNECTOR_CONFIGURATION.UPLOAD_AVATAR.REQUEST, { file, id, avatarType, connectorType }),
  success: () => action(Types.CONNECTOR_CONFIGURATION.UPLOAD_AVATAR.SUCCESS),
  failure: (error) => action(Types.CONNECTOR_CONFIGURATION.UPLOAD_AVATAR.FAILURE, { error }),
};

/** TEAMS */

export const getTeamsConfigurationZip = {
  request: (config: Models.ConnectorConfiguration) => action(Types.CONNECTOR_CONFIGURATION.ZIP.REQUEST, { config }),
  success: () => action(Types.CONNECTOR_CONFIGURATION.ZIP.SUCCESS),
  failure: (error) => action(Types.CONNECTOR_CONFIGURATION.ZIP.FAILURE, { error }),
};

/** META */

export const createMetaWebhook = {
  request: (connectorUUID: string, pageType: string, callbackModal: ReactNode) =>
    action(Types.META_CONFIGURATION.WEBHOOK.REQUEST, { connectorUUID, pageType, callbackModal }),
  success: (data?: API_CONNECTOR.Schemas.MetaConnectorAppInfoWebhookSubscriptionDTO[]) =>
    action(Types.META_CONFIGURATION.WEBHOOK.SUCCESS, { data }),
  failure: (error) => action(Types.META_CONFIGURATION.WEBHOOK.FAILURE, { error }),
};

export const updateMetaAppCredentials = {
  request: (connectorUUID: string, credentials: { clientId: string; clientSecret: string }) =>
    action(Types.META_CONFIGURATION.CREDENTIALS.REQUEST, { connectorUUID, credentials }),
  success: (data) => action(Types.META_CONFIGURATION.CREDENTIALS.SUCCESS, { data }),
  failure: (error) => action(Types.META_CONFIGURATION.CREDENTIALS.FAILURE, { error }),
};

export const createMetaLinkedObject = {
  request: (
    connectorUUID: string,
    data: {
      objectId: string;
      object: API_CONNECTOR.Schemas.MetaObjectDTO;
      accessToken: string;
    }
  ) => action(Types.META_LINKED_OBJECT.CREATE_ONE.REQUEST, { connectorUUID, data }),
  success: (data: API_CONNECTOR.Schemas.MetaLinkedObjectDTO) =>
    action(Types.META_LINKED_OBJECT.CREATE_ONE.SUCCESS, { data }),
  failure: (error) => action(Types.META_LINKED_OBJECT.CREATE_ONE.FAILURE, { error }),
};

export const updateMetaLinkedObject = {
  request: (connectorUUID: string, linkedObjectUUID: string, data: any) =>
    action(Types.META_LINKED_OBJECT.UPDATE_ONE.REQUEST, { connectorUUID, linkedObjectUUID, data }),
  success: (data: API_CONNECTOR.Schemas.MetaLinkedObjectDTO) =>
    action(Types.META_LINKED_OBJECT.UPDATE_ONE.SUCCESS, { data }),
  failure: (error) => action(Types.META_LINKED_OBJECT.UPDATE_ONE.FAILURE, { error }),
};

export const deleteMetaLinkedObject = {
  request: (connectorUUID: string, linkedObjectUUID: string) =>
    action(Types.META_LINKED_OBJECT.DELETE_ONE.REQUEST, { connectorUUID, linkedObjectUUID }),
  success: (id: string) => action(Types.META_LINKED_OBJECT.DELETE_ONE.SUCCESS, { id }),
  failure: (error) => action(Types.META_LINKED_OBJECT.DELETE_ONE.FAILURE, { error }),
};
