import { AxiosResponse } from 'axios';
import BaseApi from './BaseApi';
import { Headers } from '../';

export default class ConnectorApi extends BaseApi {
  constructor(baseUrl: string, token: string, headers: Headers) {
    super(`${baseUrl}/api/dydubox_connector`, token, headers);
  }

  getConnectorConfigurationsList = (
    botUUID: string
  ): Promise<AxiosResponse<API_CONNECTOR.Schemas.ConnectorDTOConnectorInfoDTO[]>> =>
    this.secureAxiosClient.get<API_CONNECTOR.Schemas.ConnectorDTOConnectorInfoDTO[]>(`/connectors/${botUUID}`);

  getConnectorConfiguration = (
    connectorUUID: string,
    type: string
  ): Promise<AxiosResponse<API_CONNECTOR.Schemas.ConnectorDTOConnectorInfoDTO>> =>
    this.secureAxiosClient.get<API_CONNECTOR.Schemas.ConnectorDTOConnectorInfoDTO>(
      `/connectors/${type}/${connectorUUID}`
    );

  createConnectorConfiguration = (
    data: API_CONNECTOR.Schemas.ConnectorDTOConnectorInfoDTO,
    type: string
  ): Promise<AxiosResponse<API_CONNECTOR.Schemas.ConnectorDTOConnectorInfoDTO>> =>
    this.secureAxiosClient.post<API_CONNECTOR.Schemas.ConnectorDTOConnectorInfoDTO>(`/connectors/${type}`, data);

  updateConnectorConfiguration = (
    data: API_CONNECTOR.Schemas.ConnectorDTOConnectorInfoDTO,
    type: string
  ): Promise<AxiosResponse<API_CONNECTOR.Schemas.ConnectorDTOConnectorInfoDTO>> =>
    this.secureAxiosClient.put<API_CONNECTOR.Schemas.ConnectorDTOConnectorInfoDTO>(`/connectors/${type}`, data);

  updateConnectorConfigurationName = (
    connectorUUID: string,
    name: string
  ): Promise<AxiosResponse<API_CONNECTOR.Schemas.ConnectorDTOConnectorInfoDTO>> =>
    this.secureAxiosClient.put<API_CONNECTOR.Schemas.ConnectorDTOConnectorInfoDTO>(
      `/connectors/${connectorUUID}/rename`,
      name,
      {
        headers: {
          'Content-Type': 'text/plain',
        },
      }
    );

  deleteConnectorConfiguration = (configId: string, type: string) =>
    this.secureAxiosClient.delete<string>(`/connectors/${type}/${configId}`);

  /**
   * IMAGES
   */

  getAvatar = (
    connectorUUID: string,
    avatarType: API_CONNECTOR.Schemas.AvatarType
  ): Promise<AxiosResponse<API_CONNECTOR.Schemas.TeamsConnectorAvatarDTO>> =>
    this.secureAxiosClient.get<API_CONNECTOR.Schemas.TeamsConnectorAvatarDTO>(`/images/${connectorUUID}/${avatarType}`);

  getAvatars = (connectorUUID: string): Promise<AxiosResponse<API_CONNECTOR.Schemas.TeamsConnectorAvatarDTO[]>> =>
    this.secureAxiosClient.get<API_CONNECTOR.Schemas.TeamsConnectorAvatarDTO[]>(`/images/${connectorUUID}`);

  uploadConnectorAvatar = (
    data: FormData,
    connectorUUID: string,
    imageType: API_CONNECTOR.Schemas.AvatarType,
    type: string
  ) =>
    this.secureAxiosClient.put(`/connectors/${type}/avatars/${connectorUUID}/${imageType}`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

  /**
   * TEAMS APIS
   */

  getTeamsConfigurationZip = (microsoftAppId: string): Promise<AxiosResponse<string>> =>
    this.secureAxiosClient.get<string>(`/zip/teams/${microsoftAppId}`, {
      responseType: 'blob',
      headers: {
        Accept: 'application/octet-stream,application/json',
      },
    });

  /**
   * META APIS
   */

  createMetaWebhookSubscription = (
    connectorUUID: string,
    object: API_CONNECTOR.Schemas.MetaObjectDTO
  ): Promise<AxiosResponse<API_CONNECTOR.Schemas.MetaConnectorAppInfoWebhookSubscriptionDTO>> =>
    this.secureAxiosClient.post<API_CONNECTOR.Schemas.MetaConnectorAppInfoWebhookSubscriptionDTO>(
      `/meta/${connectorUUID}/${object}/subscribe`
    );

  createMetaLinkedObject = (
    connectorUUID: string,
    data: {
      objectId: string;
      object: API_CONNECTOR.Schemas.MetaObjectDTO;
      accessToken: string;
    }
  ): Promise<AxiosResponse<API_CONNECTOR.Schemas.MetaLinkedObjectDTO>> =>
    this.secureAxiosClient.post<API_CONNECTOR.Schemas.MetaLinkedObjectDTO>(
      `/connectors/meta/${connectorUUID}/linkedobject`,
      data
    );

  updateMetaAppCredentials = (
    connectorUUID: string,
    data: {
      clientId: string;
      clientSecret: string;
    }
  ): Promise<AxiosResponse<API_CONNECTOR.Schemas.MetaConnectorDTO>> =>
    this.secureAxiosClient.put<API_CONNECTOR.Schemas.MetaConnectorDTO>(
      `/connectors/meta/${connectorUUID}/appcredentials`,
      data
    );

  updateMetaLinkedObject = (
    connectorUUID: string,
    linkedObjectUUID: string,
    data: API_CONNECTOR.Schemas.MetaLinkedObjectDTO
  ): Promise<AxiosResponse<API_CONNECTOR.Schemas.MetaLinkedObjectDTO>> =>
    this.secureAxiosClient.put<API_CONNECTOR.Schemas.MetaLinkedObjectDTO>(
      `/connectors/meta/${connectorUUID}/${linkedObjectUUID}`,
      data
    );

  deleteMetaLinkedObject = (
    connectorUUID: string,
    linkedObjectUUID: string
  ): Promise<AxiosResponse<API_CONNECTOR.Schemas.MetaLinkedObjectDTO>> =>
    this.secureAxiosClient.delete<API_CONNECTOR.Schemas.MetaLinkedObjectDTO>(
      `/connectors/meta/${connectorUUID}/${linkedObjectUUID}`
    );
}
