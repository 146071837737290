import styled from 'styled-components';

export const MainContainer = styled.div`
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background-color: ${({ theme }) => theme.background_main_grey};
`;

export const InnerContainer = styled.div`
  position: relative;
  max-width: 192rem;
  width: 100%;
  padding: 2rem 3.6rem 3.6rem;
  margin: 0 auto;
`;

export const HeadContainer = styled.span`
  display: inline-block;
  width: 100%;
  margin-bottom: 6px;
`;

export const VersionContainer = styled.span`
  float: right;
  text-align: right;
  color: ${({ theme }) => theme.color_grey__400};
  font-size: 10px;
`;
