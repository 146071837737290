import { createContext, useContext, useEffect, useState } from 'react';
import { getAllCallBotVoicesAvailables, getCallBotConfigurations } from '../Api/Tools/Api';
import { useSelector } from 'react-redux';
import { getCurrentBot } from '../Redux/Bot/selectors';
import { getUserHost } from '../Redux/User/selectors';
import { APP_PATH } from '../Tools/Constants';
import { toastError } from '../Tools/Toast';
import { t } from 'i18next';

interface CallBotConfigProps {
  callBotConfigurations: any;
  setCallBotConfigurations: any;
  voicesListForSelectedInstance: any;
  selectedCallBotConfig: any;
  setSelectedCallBotConfig: any;
}

export const useCallBotConfigInfo = () => useContext<CallBotConfigProps>(CallBotConfigContext);
export const CallBotConfigContext = createContext({} as any);

const CallBotConfigProvider = ({ children }: { children: React.ReactNode }) => {
  const [callBotConfigurations, setCallBotConfigurations] = useState<any>([]);
  const [selectedCallBotConfig, setSelectedCallBotConfig] = useState<any>(null);
  const [voicesListForSelectedInstance, setVoicesListForSelectedInstance] = useState([]);
  const currentBot = useSelector(getCurrentBot);
  const host = useSelector(getUserHost);

  const urlCallbot = window?.DYDU_TARGETS?.callbot_url;

  const urlIsRagConfiguration = window.location.href.includes(APP_PATH.EXTERNAL_CONTENTS);

  const isObject = (item) => {
    return item && typeof item === 'object' && !Array.isArray(item);
  };

  useEffect(() => {
    if (urlCallbot && isObject(currentBot) && !urlIsRagConfiguration) {
      getCallBotConfigurations(host, currentBot?.botUUID || '')
        .then((res: any) => {
          setCallBotConfigurations(res);
        })
        .catch((error: any) => {
          console.log(error);
          toastError(t('edition.callbot.errorGetConfigurations'));
        });
    }
  }, [currentBot]);

  useEffect(() => {
    selectedCallBotConfig &&
      getAllCallBotVoicesAvailables(selectedCallBotConfig.uuid)
        .then((res) => {
          setVoicesListForSelectedInstance(res);
        })
        .catch((error: any) => {
          console.log(error);
          toastError(t('edition.callbot.errorGetVoicesAvailable'));
        });
  }, [selectedCallBotConfig]);

  const props = {
    selectedCallBotConfig,
    callBotConfigurations,
    voicesListForSelectedInstance,
    setCallBotConfigurations,
    setSelectedCallBotConfig,
  };

  return <CallBotConfigContext.Provider children={children} value={props} />;
};

export default CallBotConfigProvider;
