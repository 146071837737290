import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { useRef, useState } from 'react';
import { useRagConfig } from '../../../../../../Contexts/RagConfigContext';
import { addRagConfigurationSourceFile, getToken } from '../../../../../../Api/Tools/Api';
import { toastError, toastSuccess } from '../../../../../../Tools/Toast';
import { ProgressBar } from 'primereact/progressbar';
import { useTranslation } from 'react-i18next';
import { ModalFilesUploadWrapper } from '../Style';
import { useUserContext } from '../../../../../../Contexts/UserContext';
import { HeaderWrapper } from '../../../../../ExternalContents/Style';

interface ModalFilesUploadProps {
  visible: boolean;
  onHide: () => void;
  setVisible: (visible: boolean) => void;
}

const ModalFilesUpload = ({ visible, onHide, setVisible }: ModalFilesUploadProps) => {
  const { selectedRagConfiguration, setSelectedRagConfiguration } = useRagConfig();
  const [uploadProgress, setUploadProgress] = useState(0);
  const { t } = useTranslation();
  const fileUploadRef = useRef<FileUpload>(null);
  const { logout } = useUserContext();

  const renderProgressBar = () => {
    return <ProgressBar value={uploadProgress} />;
  };

  const uploadEachFile = async (e: any) => {
    let updatedConfiguration: API_BACKEND.Schemas.RAGConfigurationDTO = {
      ...selectedRagConfiguration,
      id: selectedRagConfiguration?.id || '',
      name: selectedRagConfiguration?.name,
      botUUID: selectedRagConfiguration?.botUUID,
      content: {
        ...selectedRagConfiguration?.content,
        sources: {
          ...selectedRagConfiguration?.content?.sources,
          files: [...(selectedRagConfiguration?.content?.sources?.files || [])],
        },
      },
    };
    for (let i = 0; i < e.files.length; i++) {
      selectedRagConfiguration &&
        (await addRagConfigurationSourceFile(selectedRagConfiguration?.id, e.files[i], getToken(), (progress) => {
          setUploadProgress(progress / e.files.length);
        })
          .then((res) => {
            updatedConfiguration = {
              ...updatedConfiguration,
              content: {
                ...updatedConfiguration.content,
                sources: {
                  ...updatedConfiguration.content?.sources,
                  files: [...(updatedConfiguration.content?.sources?.files || []), res],
                },
              },
            };
          })
          .catch((error: any) => {
            if (error?.response?.status == '401') {
              logout();
            }
            if (fileUploadRef.current) fileUploadRef.current.clear();
            toastError(t('externalContents.rag.uploadFile.uploadError'));
          }));
    }
    setSelectedRagConfiguration && setSelectedRagConfiguration(updatedConfiguration);
    if (fileUploadRef.current) fileUploadRef.current.clear();
    toastSuccess(t('externalContents.rag.uploadFile.uploadSuccess'));
    setVisible(false);
  };

  const renderHeaderModal = (
    <HeaderWrapper>
      <h1 className='p-panel-title'>{t('externalContents.rag.uploadFile.title')}</h1>
    </HeaderWrapper>
  );

  const labelForUploadContent = () => (
    <ModalFilesUploadWrapper>
      <div className={'content-label'}>{t('externalContents.rag.uploadFile.contentLabel')}</div>
    </ModalFilesUploadWrapper>
  );

  return (
    <>
      <Dialog
        visible={visible}
        onHide={onHide}
        dismissableMask={true}
        style={{ width: '80%' }}
        header={renderHeaderModal}
      >
        <ModalFilesUploadWrapper>
          <h2 className='p-panel-subtitle'>{t('externalContents.rag.uploadFile.subtitle')}</h2>
          <FileUpload
            ref={fileUploadRef}
            progressBarTemplate={renderProgressBar()}
            name={'file'}
            maxFileSize={10485760}
            uploadHandler={uploadEachFile}
            customUpload={true}
            accept='.pdf, .txt, .docx, .pptx'
            multiple={true}
            emptyTemplate={labelForUploadContent()}
            contentStyle={{ height: '200px', overflow: 'auto' }}
            cancelOptions={{ style: { display: 'none' } }}
            uploadLabel={t('externalContents.rag.uploadFile.upload')}
            chooseLabel={t('externalContents.rag.uploadFile.choose')}
            invalidFileSizeMessageSummary={t('externalContents.rag.uploadFile.invalidFileSizeMessageSummary')}
            invalidFileSizeMessageDetail={''}
          />
        </ModalFilesUploadWrapper>
      </Dialog>
    </>
  );
};

export default ModalFilesUpload;
