import { CHATBOX_VERSION } from 'src/Tools/Constants';
import { getUserHost, getUserId } from 'src/Redux/User/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { checkChatboxAvailability } from 'src/Redux/App/actions';
import { useEffect } from 'react';
import { getBotLanguages, getBots, getConsultationSpaces } from '../../Redux/Bot/actions';
import { getCurrentBot } from '../../Redux/Bot/selectors';

const HeaderContainer = () => {
  const dispatch = useDispatch();

  const currentBot = useSelector(getCurrentBot);
  const host = useSelector(getUserHost);
  const userId = useSelector(getUserId);

  useEffect(() => {
    dispatch(checkChatboxAvailability.request(CHATBOX_VERSION));
  }, []);

  useEffect(() => {
    if (host && !currentBot?.botUUID && userId) {
      dispatch(getBots.request(userId));
    }
  }, []);

  const getBotRelatedData = (bot) => {
    dispatch(getBotLanguages.request(bot?.botUUID));
    dispatch(getConsultationSpaces.request(bot?.botUUID, bot?.defaultLanguage));
  };

  useEffect(() => {
    if (currentBot?.botUUID && host) {
      getBotRelatedData(currentBot);
    }
  }, [currentBot?.botUUID, host]);

  return <></>;
};

export default HeaderContainer;
