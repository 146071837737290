import { CONFIG_TYPES, UPLOAD_TYPE } from 'src/Tools/Constants';
import { CONNECTOR_CONFIGURATION, WEBSITE_CONFIGURATION } from 'src/Redux/Configurations/types';
import {
  createWebsiteConfiguration,
  importWebsiteConfiguration,
} from 'src/Redux/Configurations/actions/websiteActions';
import { getUserHost, getUserLang, getUserName } from 'src/Redux/User/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';

import { ActionProps } from 'src/Components/Actions/Actions';
import CreateForm from './Form/CreateForm';
import CreateType from './Form/CreateType';
import { MainContainer } from './Style';
import { createConnectorConfiguration } from 'src/Redux/Configurations/actions/connectorActions';
import { getCurrentBot } from 'src/Redux/Bot/selectors';
import { getLoadingStatuses } from 'src/Redux/Loading/selectors';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../../Contexts/UserContext';
import { getMessageError } from '../../Redux/Configurations/selectors';
import { useCallBotConfigInfo } from '../../Contexts/CallBotContext';
import { createCallBotConfigurationForDemo, getCallBotConfigurations } from '../../Api/Tools/Api';
import { useNavigate } from 'react-router-dom';
import { toastError } from '../../Tools/Toast';

type ModalMode = 'new' | 'import' | 'replace' | null;

type FileType = File | null;

interface CreateConfigContainerProps {
  type?: string;
  config?: Models.WebsiteConfiguration;
  forcedMode?: ModalMode;
}

export interface CreateConfigProps extends CreateConfigContainerProps {
  createConfig: () => void;
  type?: string;
  config?: Models.WebsiteConfiguration;
  actions: ActionProps[];
  createType: ModalMode;
  setCreateType: (type: ModalMode) => void;
  configName: string;
  setConfigName: (name: string) => void;
  fileRef: any;
  configFile: FileType;
  setConfigFile: (file: FileType) => void;
  selectFile: () => void;
  setLanguage: (lang: string) => void;
  setConsultationSpace: (space: string) => void;
  consultationSpace: string;
  language: string;
}

const CreateConfigContainer = ({ type, config, forcedMode = null }: CreateConfigContainerProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userName = useSelector(getUserName);
  const userLang = useSelector(getUserLang);
  const currentBot = useSelector(getCurrentBot);
  const fileRef = useRef<any>(null);
  const [createType, setCreateType] = useState<ModalMode>(forcedMode);
  const [configName, setConfigName] = useState<string>(config?.name || '');
  const [configFile, setConfigFile] = useState<FileType>(null);
  const [language, setLanguage] = useState<string>('');
  const [consultationSpace, setConsultationSpace] = useState<string>('');
  const [isCreatingCallBotDemo, setIsCreatingCallBotDemo] = useState(false);
  const { logout } = useUserContext();
  const messageError = useSelector(getMessageError);
  const host = useSelector(getUserHost);
  const navigate = useNavigate();
  const { setCallBotConfigurations } = useCallBotConfigInfo();

  useEffect(() => {
    if (messageError?.endsWith('401')) {
      logout();
    }
  }, [messageError]);

  const isLoading = useSelector((state) =>
    getLoadingStatuses(state, [
      WEBSITE_CONFIGURATION.IMPORT_ONE.REQUEST,
      WEBSITE_CONFIGURATION.CREATE_ONE.REQUEST,
      CONNECTOR_CONFIGURATION.CREATE_ONE.REQUEST,
    ])
  );

  const handleCancel = () => {
    setCreateType(null);
    setConfigFile(null);
    setConfigName('');
    setLanguage('');
    setConsultationSpace('');
    fileRef.current.value = '';
  };

  const handleCreateConfig = () => {
    if (currentBot?.botUUID && type && type !== CONFIG_TYPES.CALLBOT && userName) {
      const data = {
        name: configName,
        type: type,
        language: userLang,
        botUUID: currentBot?.botUUID,
        modifiedBy: userName || '',
      };

      switch (data.type) {
        case CONFIG_TYPES.WEBSITE:
        case CONFIG_TYPES.CUSTOMER:
          return dispatch(createWebsiteConfiguration.request(data));
        case CONFIG_TYPES.META:
        case CONFIG_TYPES.TEAMS:
          return dispatch(createConnectorConfiguration.request(data));
        default:
          throw 'Config type not yet suported';
      }
    }
    if (type === CONFIG_TYPES.CALLBOT) {
      const data = {
        atriaServer: host,
        botUUID: currentBot?.botUUID,
        instanceName: encodeURIComponent(currentBot?.name || ''),
        language: language[0],
        consultationSpace: encodeURIComponent(consultationSpace[0] || ''),
      };
      setIsCreatingCallBotDemo(true);
      createCallBotConfigurationForDemo(data)
        .then((res) => {
          if (res.uuid) {
            currentBot &&
              getCallBotConfigurations(host, currentBot?.botUUID).then((res) => {
                setCallBotConfigurations(res);
              });
            navigate(`/configurations/${CONFIG_TYPES.CALLBOT}/${res?.uuid}`);
            setIsCreatingCallBotDemo(false);
          } else {
            toastError(t('edition.callbot.errorCreateDemo'));
            setIsCreatingCallBotDemo(false);
          }
        })
        .catch(() => {
          toastError(t('edition.callbot.errorCreateDemo'));
          setIsCreatingCallBotDemo(false);
        });
    }
  };

  const handleConfigImport = () =>
    type &&
    configFile &&
    configName &&
    dispatch(importWebsiteConfiguration.request(type, configName, configFile, config?.id));

  const hanleSelectFile = () => fileRef.current.click();

  const hanleFileChange = (e) => {
    setConfigFile(e.target.files[0]);
    setCreateType(createType || 'import');
  };

  const disabledCreateForCallBotDemo = type === CONFIG_TYPES.CALLBOT && (!language || !consultationSpace);

  const actions: ActionProps[] = [
    {
      label: t('general.previous'),
      className: 'p-button-text p-button-secondary',
      icon: 'icon-caret-left',
      onClick: handleCancel,
      hidden: forcedMode !== null,
    },
    {
      label: t('general.create'),
      icon: 'icon-check',
      hidden: createType !== 'new',
      disabled: configName.length < 1 || isLoading || disabledCreateForCallBotDemo || isCreatingCallBotDemo,
      onClick: () => handleCreateConfig(),
    },
    {
      label: t('general.import'),
      icon: 'icon-check',
      hidden: createType === 'new',
      disabled: !configFile || configName.length < 1,
      loading: isLoading,
      onClick: () => handleConfigImport(),
    },
  ];

  const props: CreateConfigProps = {
    createConfig: handleCreateConfig,
    type,
    actions,
    createType,
    setCreateType,
    configName,
    setConfigName,
    forcedMode,
    fileRef,
    configFile,
    setConfigFile,
    config,
    selectFile: hanleSelectFile,
    setLanguage,
    setConsultationSpace,
    consultationSpace,
    language,
  };

  return (
    <MainContainer>
      {createType || (createType && ['import', 'replace'].includes(createType) && configFile) ? (
        <CreateForm {...props} />
      ) : (
        <CreateType {...props} />
      )}
      <input className='file-input' ref={fileRef} type='file' accept={UPLOAD_TYPE.ZIP} onChange={hanleFileChange} />
    </MainContainer>
  );
};

export default CreateConfigContainer;
