import * as Actions from './actions';
import * as Types from './types';

import { all, call, put, select, takeLatest } from 'typed-redux-saga';
import { toastError } from 'src/Tools/Toast';

import { encodeBase64 } from 'src/Tools/Text';
import { getClientApi } from 'src/Api/Tools/Api';
import { getUserProxy } from '../User/selectors';
import { ApiProps } from 'src/Api';

/**
 * Check if CDN Url exist or not
 * @param param0
 */
function* checkChatboxAvailability({ payload: { version } }) {
  try {
    const proxy = yield select(getUserProxy);
    const Client: ApiProps = yield call(getClientApi);
    yield* call(Client.backend.checkChatboxAvailability, { version, ...(proxy && { proxy: encodeBase64(proxy) }) });
    yield put(Actions.checkChatboxAvailability.success());
  } catch (error) {
    yield put(Actions.checkChatboxAvailability.failure(error));
    toastError(error);
  }
}

export default function* appSaga() {
  try {
    yield all([takeLatest(Types.CHATBOX.CHECK.REQUEST, checkChatboxAvailability)]);
  } catch (error: any) {
    toastError(error);
  }
}
