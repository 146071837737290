import { CONFIGURATIONS, WEBSITE_CONFIGURATION } from 'src/Redux/Configurations/types';
import { HeadContainer, InnerContainer, MainContainer, VersionContainer } from './Style';

import HeaderContainer from 'src/Components/Header/HeaderContainer';
import Loader from 'src/Components/Loader/Loader';
import docVersion from 'src/version.json';
import { useSelector } from 'react-redux';
import { getSelectedConfig } from '../../Redux/Configurations/selectors';
import BreadcrumbContainer from '../../Components/Breadcrumb/BreadcrumbContainer';

const WithLayout = (WrappedComponent: any) => {
  return (props) => {
    const renderLoader = () => (
      <Loader actions={[CONFIGURATIONS.GET_ALL.REQUEST, WEBSITE_CONFIGURATION.PUBLISH_ONE.REQUEST]} />
    );

    const renderVersion = () => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const selectedConfig = useSelector(getSelectedConfig);
      return (
        docVersion?.branch && (
          <HeadContainer>
            {selectedConfig && <BreadcrumbContainer />}
            <VersionContainer>{docVersion.branch}</VersionContainer>
          </HeadContainer>
        )
      );
    };

    return (
      <MainContainer>
        <HeaderContainer />
        <InnerContainer>
          {renderLoader()}
          {renderVersion()}
          <WrappedComponent {...props} />
        </InnerContainer>
      </MainContainer>
    );
  };
};

export default WithLayout;
