import { AxiosResponse } from 'axios';
import BaseApi from './BaseApi';
import { Headers } from '../';

export default class BackendApi extends BaseApi {
  constructor(baseUrl: string, token: string, headers: Headers) {
    super(`${baseUrl}/api/dydubox_backend`, token, headers);
  }

  /**
   * Configurations
   */

  getWebsiteConfigurationsList = (
    botUUID: string
  ): Promise<AxiosResponse<API_BACKEND.Schemas.DyduboxConfigurationDTO[]>> =>
    this.secureAxiosClient.get<API_BACKEND.Schemas.DyduboxConfigurationDTO[]>(`/configurations/bot/${botUUID}`);

  getWebsiteConfiguration = (configId: string): Promise<AxiosResponse<API_BACKEND.Schemas.DyduboxConfigurationDTO>> =>
    this.secureAxiosClient.get<API_BACKEND.Schemas.DyduboxConfigurationDTO>(`/configurations/${configId}`);

  createWebsiteConfiguration = (data): Promise<AxiosResponse<API_BACKEND.Schemas.DyduboxConfigurationDTO>> =>
    this.secureAxiosClient.post<API_BACKEND.Schemas.DyduboxConfigurationDTO>(`/configurations`, data);

  updateWebsiteConfiguration = (
    data: API_BACKEND.Schemas.DyduboxConfigurationDTO
  ): Promise<AxiosResponse<API_BACKEND.Schemas.DyduboxConfigurationDTO>> =>
    this.secureAxiosClient.put<API_BACKEND.Schemas.DyduboxConfigurationDTO>(`/configurations`, data);

  updateWebsiteConfigurationName = (
    configId: string,
    name: string
  ): Promise<AxiosResponse<API_BACKEND.Schemas.DyduboxConfigurationDTO>> =>
    this.secureAxiosClient.put<API_BACKEND.Schemas.DyduboxConfigurationDTO>(
      `/configurations/${configId}/rename`,
      name,
      {
        headers: {
          'Content-Type': 'text/plain',
        },
      }
    );

  updateWebsiteConfigurationSlug = (
    configId: string,
    slug: string
  ): Promise<AxiosResponse<API_BACKEND.Schemas.DyduboxConfigurationDTO>> =>
    this.secureAxiosClient.put<API_BACKEND.Schemas.DyduboxConfigurationDTO>(`/configurations/${configId}/slug`, slug, {
      headers: {
        'Content-Type': 'text/plain',
      },
    });

  importWebsiteConfiguration = (data: {
    formData: FormData;
    name: string;
    author: string;
    type: string;
    botUUID: string;
    configUUID?: string;
    proxy?: string;
  }): Promise<AxiosResponse<API_BACKEND.Schemas.DyduboxConfigurationDTO>> => {
    const { formData, name, author, type, botUUID, configUUID, proxy } = data;
    return this.secureAxiosClient.post<API_BACKEND.Schemas.DyduboxConfigurationDTO>(
      `/configurations/import`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          author,
          type,
          botUUID,
          name,
          configUUID,
          ...(proxy ? { proxy } : null),
        },
      }
    );
  };

  deleteWebsiteConfiguration = (configId: string) => this.secureAxiosClient.delete(`/configurations/${configId}`);

  publishWebsiteConfiguration = (data: {
    configId: string;
    env: string;
    userName: string;
    version: string;
    configurationContent?: API_BACKEND.Schemas.DyduboxConfigurationDTO;
    proxy?: string;
  }): Promise<AxiosResponse<string>> => {
    const { configId, env, userName, version, configurationContent, proxy } = data;
    return this.secureAxiosClient.post<string>(
      `/configurations/publish/${configId}/${env}`,
      {
        publicationAuthor: userName,
        version,
        ...(configurationContent ? { configurationContent } : null),
      },
      {
        responseType: 'text',
        params: {
          ...(proxy ? { proxy } : null),
        },
      }
    );
  };
  restorePublishedConfiguration = (data: {
    configId: string;
    publicationId: string;
  }): Promise<AxiosResponse<string>> => {
    const { configId, publicationId } = data;
    return this.secureAxiosClient.post<string>(`/configurations/restore/${configId}/${publicationId}`);
  };
  /**
   * IMAGES
   */

  getImage = (
    configId: string,
    imageType: API_BACKEND.Schemas.ImageType
  ): Promise<AxiosResponse<API_BACKEND.Schemas.ImageConfigurationDTO>> =>
    this.secureAxiosClient.get<API_BACKEND.Schemas.ImageConfigurationDTO>(`/images/${configId}/${imageType}`);

  getImages = (configId: string): Promise<AxiosResponse<API_BACKEND.Schemas.ImageConfigurationDTO[]>> =>
    this.secureAxiosClient.get<API_BACKEND.Schemas.ImageConfigurationDTO[]>(`/images/${configId}`);

  uploadImage = (data: FormData, configId: string, imageType: API_BACKEND.Schemas.ImageType) =>
    this.secureAxiosClient.put(`/images/${configId}/${imageType}`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

  /**
   * WORDING
   */

  getWording = (configId: string): Promise<AxiosResponse<API_BACKEND.Schemas.LanguageTranslationDTO[]>> =>
    this.secureAxiosClient.get<API_BACKEND.Schemas.LanguageTranslationDTO[]>(`/translation/${configId}`);

  getWordingByLang = (configId: string, lang: string): Promise<AxiosResponse<API_BACKEND.Schemas.JsonNode>> =>
    this.secureAxiosClient.get<API_BACKEND.Schemas.JsonNode>(`/translation/${configId}/${lang}`);

  uploadTranslationExcel = (data: FormData, configId: string, lang?: string) => {
    const params = lang ? { languageCode: lang } : {};
    return this.secureAxiosClient.post(`/translation/upload-excel/${configId}`, data, { params });
  };

  uploadTranslationJson = (configId: string, lang: string, translation: any, replaceIfExist: boolean) => {
    const params = {
      replaceIfExist: replaceIfExist,
    };
    return this.secureAxiosClient.post(`/translation/${configId}/${lang}`, translation, { params });
  };

  downloadTranslation = (configId: string, defaultTranslationJson: any) => {
    return this.secureAxiosClient.post(`/translation/download-excel/${configId}`, defaultTranslationJson, {
      responseType: 'arraybuffer',
    });
  };

  /**
   * Check if cdn preview is present
   */

  checkChatboxAvailability = (data: { version: string; proxy?: string }): Promise<AxiosResponse<any>> => {
    const { version, proxy } = data;
    return this.secureAxiosClient.get<any>(`/chatbox/check/${version}`, {
      params: {
        ...(proxy ? { proxy } : null),
      },
    });
  };
}
