import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';
import Input from '../../../../../../Components/Input/Input';
import Button from '../../../../../../Components/Button/Button';
import { useEffect, useState } from 'react';
import { ButtonAdd, FormAddUrl, MessageAlignLeft, ModalUrls, UrlListContainer } from '../Style';
import { HeaderWrapper } from '../../../../../ExternalContents/Style';
import { SelectButton } from 'primereact/selectbutton';
import { toastError } from '../../../../../../Tools/Toast';
import { InputTextarea, InputWrapper, Label } from '../../../../../../Components/Input/Style';
import { UrlType } from './WebsiteUrlType';

interface ModalWebsitesProps {
  visible: boolean;
  onHide: () => void;
  changeCurrentWebsiteValues: (name: string, type: UrlType, urls: string[], url: string) => Promise<void>;
  currentWebSite: any;
}

const ModalWebsites = ({ visible, onHide, changeCurrentWebsiteValues, currentWebSite }: ModalWebsitesProps) => {
  const { t } = useTranslation();
  const [websiteName, setWebsiteName] = useState<string>('');
  const [urlValue, setUrlValue] = useState<string>('');
  const [urlsValue, setUrlsValue] = useState<string[]>([]);

  const [newSpecificUrls, setNewSpecificUrls] = useState('');
  const [urlType, setUrlType] = useState(1);
  const [isInvalidName, setIsInvalidName] = useState(false);
  const [isInvalidInput, setIsInvalidInput] = useState(false);

  const isUpdate = !!currentWebSite;

  useEffect(() => {
    const type: UrlType = getTypeFromConfig(currentWebSite);

    if (currentWebSite) {
      setWebsiteName(currentWebSite.name);

      if (type === UrlType.SpecificUrl) {
        setUrlsValue(currentWebSite?.urls);
      } else if (type === UrlType.DomainToCrawl) {
        setUrlValue(currentWebSite?.domainToCrawl);
      } else if (type === UrlType.SitemapUrl) {
        setUrlValue(currentWebSite?.sitemapUrl);
      }
    } else {
      setWebsiteName('');
      setUrlsValue([]);
      setUrlValue('');
    }

    if (isUpdate) {
      setUrlType(type);
    }
  }, [currentWebSite]);

  const getTypeFromConfig = (config) => {
    let type: UrlType;
    if (config?.domainToCrawl) {
      type = UrlType.DomainToCrawl;
    } else if (config?.sitemapUrl) {
      type = UrlType.SitemapUrl;
    } else {
      type = UrlType.SpecificUrl;
    }
    return type;
  };

  const isValidUrl = (urlToCheck): boolean => {
    try {
      return Boolean(new URL(urlToCheck));
    } catch (e) {
      return false;
    }
  };

  const listOfWebsiteType = [
    {
      name: t('externalContents.rag.websites.urlTypeDomain'),
      value: UrlType.DomainToCrawl,
      disabled: isUpdate && urlType !== UrlType.DomainToCrawl,
    },
    {
      name: t('externalContents.rag.websites.urlTypeSitemap'),
      value: UrlType.SitemapUrl,
      disabled: isUpdate && urlType !== UrlType.SitemapUrl,
    },
    {
      name: t('externalContents.rag.websites.urlTypeSpecific'),
      value: UrlType.SpecificUrl,
      disabled: isUpdate && urlType !== UrlType.SpecificUrl,
    },
  ];

  const handleAddUrl = () => {
    const invalidUrls: string[] = [];
    const validUrls: string[] = urlsValue ? [...urlsValue] : [];

    for (const url of newSpecificUrls.split('\n')) {
      if (!isValidUrl(url)) {
        setIsInvalidInput(true);
        toastError(t('externalContents.rag.websites.invalidUrlError'));
        invalidUrls.push(url);
      } else if (validUrls?.indexOf(url) === -1) {
        validUrls?.push(url);
      }
    }

    setUrlsValue(validUrls);
    setNewSpecificUrls(invalidUrls.join('\n'));
    setIsInvalidInput(invalidUrls.length > 0);
  };

  const cleanAllData = () => {
    if (!isUpdate) {
      setWebsiteName('');
      setUrlsValue([]);
      setUrlValue('');
    }
    setNewSpecificUrls('');
  };

  const onHideModal = () => {
    cleanAllData();
    setIsInvalidName(false);
    setIsInvalidInput(false);
    onHide();
  };

  const renderHeaderModal = (
    <HeaderWrapper>
      <h1 className='p-panel-title'>{t('externalContents.rag.websites.title')}</h1>
    </HeaderWrapper>
  );

  const renderSpecificUrl = () => {
    return (
      <>
        <MessageAlignLeft text={t('externalContents.rag.websites.message.urls')} />
        <FormAddUrl>
          <Input
            label={t('general.name')}
            className={'input-name'}
            placeholder={t('general.name')}
            value={websiteName}
            invalid={isInvalidName}
            onChange={(e) => {
              setWebsiteName(e.target.value);
            }}
          />
          <InputWrapper>
            <Label>{t('externalContents.rag.websites.urls')}</Label>
            <InputTextarea
              placeholder={t('externalContents.rag.websites.placeholder.urls')}
              className={'input-url'}
              value={newSpecificUrls}
              invalid={isInvalidInput && !isValidUrl(newSpecificUrls)}
              onChange={(e) => {
                setNewSpecificUrls(e.target.value);
              }}
            />
          </InputWrapper>
          <ButtonAdd label={t('externalContents.rag.websites.addWebSite')} onClick={handleAddUrl} />
        </FormAddUrl>
        <div>{t('externalContents.rag.websites.urls')}</div>
        <UrlListContainer
          style={{
            backgroundColor: (urlsValue?.length || 0) > 0 ? '#f5f5f5' : 'transparent',
            pointerEvents: (urlsValue?.length || 0) === 0 ? 'none' : 'auto',
          }}
        >
          {!urlsValue || urlsValue?.length === 0 ? (
            <li>{t('externalContents.rag.websites.emptyMultiSelect')}</li>
          ) : (
            urlsValue?.map((url: string, index: number) => (
              <li key={index}>
                <Button
                  icon='pi pi-times'
                  className='p-button-text p-button-danger'
                  onClick={() => handleRemoveUrl(index)}
                />
                {url}
              </li>
            ))
          )}
        </UrlListContainer>
      </>
    );
  };

  const handleRemoveUrl = (index: number) => {
    const updatedUrls = [...(urlsValue || [])];
    updatedUrls.splice(index, 1);
    setUrlsValue(updatedUrls);
  };

  const renderDomainUrl = () => {
    return (
      <>
        <MessageAlignLeft text={t('externalContents.rag.websites.message.domainToCrawl')} />
        <FormAddUrl>
          <Input
            label={t('general.name')}
            className={'input-name'}
            placeholder={t('general.name')}
            value={websiteName}
            invalid={isInvalidName}
            onChange={(e) => {
              setWebsiteName(e.target.value);
            }}
          />
          <Input
            label={t('general.url')}
            placeholder={t('externalContents.rag.websites.placeholder.domainToCrawl')}
            className={'input-url'}
            value={urlValue}
            invalid={isInvalidInput}
            onChange={(e) => {
              setUrlValue(e.target.value);
            }}
          />
        </FormAddUrl>
      </>
    );
  };

  const renderSitemapUrl = () => {
    return (
      <>
        <MessageAlignLeft text={t('externalContents.rag.websites.message.sitemap')} />
        <FormAddUrl>
          <Input
            label={t('general.name')}
            className={'input-name'}
            placeholder={t('general.name')}
            value={websiteName}
            invalid={isInvalidName}
            onChange={(e) => {
              setWebsiteName(e.target.value);
            }}
          />
          <Input
            label={t('general.url')}
            placeholder={t('externalContents.rag.websites.placeholder.sitemap')}
            className={'input-url'}
            invalid={isInvalidInput}
            value={urlValue}
            onChange={(e) => {
              setUrlValue(e.target.value);
            }}
          />
        </FormAddUrl>
      </>
    );
  };

  const renderForm = () => {
    switch (urlType) {
      case UrlType.DomainToCrawl:
        return renderDomainUrl();
      case UrlType.SitemapUrl:
        return renderSitemapUrl();
      case UrlType.SpecificUrl:
        return renderSpecificUrl();
      default:
        return null;
    }
  };

  const checkIfInputIsEmpty = () => {
    switch (urlType) {
      case UrlType.DomainToCrawl:
      case UrlType.SitemapUrl:
        return urlValue === '';
      case UrlType.SpecificUrl:
        return urlsValue?.length === 0;
      default:
        return false;
    }
  };

  const checkIfNameIsEmpty = () => {
    return websiteName === '';
  };

  const checkIfInputIsValid = () => {
    switch (urlType) {
      case UrlType.DomainToCrawl:
      case UrlType.SitemapUrl:
        return isValidUrl(urlValue);
      case UrlType.SpecificUrl:
        return urlsValue && urlsValue.length > 0 && !urlsValue.some((url) => !isValidUrl(url));
      default:
        return false;
    }
  };

  const handleAddOrUpdate = () => {
    setIsInvalidName(false);
    setIsInvalidInput(false);

    if (checkIfNameIsEmpty()) {
      setIsInvalidName(true);
      toastError(t('externalContents.rag.websites.emptyNameError'));
      return;
    }

    if (checkIfInputIsEmpty()) {
      setIsInvalidInput(true);
      toastError(t('externalContents.rag.websites.emptyUrlError'));
      return;
    }

    if (!checkIfInputIsValid()) {
      setIsInvalidInput(true);
      toastError(t('externalContents.rag.websites.invalidUrlError'));
      return;
    }

    changeCurrentWebsiteValues(websiteName, urlType, urlsValue, urlValue).then(() => {
      setIsInvalidName(false);
      setIsInvalidInput(false);
      cleanAllData();
    });
  };

  const onChangeUrlType = (e) => {
    setUrlType(e.value);

    setWebsiteName('');
    setUrlValue('');
    setUrlsValue([]);

    setIsInvalidName(false);
    setIsInvalidInput(false);
  };

  return (
    <Dialog
      visible={visible}
      onHide={onHideModal}
      dismissableMask={true}
      style={{
        width: '50%',
        ...(urlType === UrlType.SpecificUrl ? { maxHeight: '68vh' } : {}),
      }}
      header={renderHeaderModal}
    >
      <ModalUrls>
        <SelectButton
          value={urlType}
          onChange={onChangeUrlType}
          optionLabel='name'
          options={listOfWebsiteType}
          optionDisabled={'disabled'}
          className={'urls-options'}
          allowEmpty={false}
        />
        {renderForm()}
        <Button
          label={t(isUpdate ? 'general.update' : 'general.create')}
          className={'button-add-website'}
          onClick={handleAddOrUpdate}
        />
      </ModalUrls>
    </Dialog>
  );
};

export default ModalWebsites;
