import axios, { AxiosResponse } from 'axios';
import Api from '../index';

let token = '';
export function getToken(): string {
  return token;
}

export function setToken(newToken: string) {
  token = newToken;
}

let serverCode = '';
export function getServerCode() {
  return serverCode;
}

export function setServerCode(newServerCode: string) {
  serverCode = newServerCode;
}

let proxy = '';
export function getProxy() {
  return proxy;
}

export function setProxy(newProxy: string) {
  proxy = newProxy;
}

let login = '';
export function getLogin() {
  return login;
}

export function setLogin(newLogin: string) {
  login = newLogin;
}

let next = '';
export function getNext() {
  return next;
}

export function setNext(newNext: string) {
  next = newNext;
}

export const appRoot = window?.DYDU_TARGETS?.app_root;
const url_callbot = window?.DYDU_TARGETS?.callbot_url;

const ATRIA_SERVLET_PATH = '/servlet';

export const getAtriaPath = () => {
  let url = `https://${serverCode}${ATRIA_SERVLET_PATH}`;

  if (proxy) {
    url = `${proxy}${ATRIA_SERVLET_PATH}`;
  }

  return url;
};

const getGatewayUrl = () => {
  if (proxy) {
    return proxy;
  }
  return window?.DYDU_TARGETS?.gateway_url;
};

export function getClientApi() {
  const headers = {};
  return Api.getInstance(getGatewayUrl(), getToken(), headers);
}

export const getCallBotConfigurations = async (atriaServer: string, botUUID: string) => {
  const headers = {
    accept: 'application/json',
    Authorization: `Basic ZHlkdTpLMEdGMUpSL2lYMWtwRWcrektTTForR1l6TVk=`,
  };
  const res: AxiosResponse = await axios.get(url_callbot + `/instances/${atriaServer}/${botUUID}`, { headers });
  return res.data;
};

export const updateCallBotVoice = async (instanceUUID: string, data: object) => {
  const headers = {
    accept: 'application/json',
    Authorization: `Basic ZHlkdTpLMEdGMUpSL2lYMWtwRWcrektTTForR1l6TVk=`,
  };
  const res: AxiosResponse = await axios.post(url_callbot + `/instances/${instanceUUID}`, data, { headers });
  return res.data;
};

export const getAllCallBotVoicesAvailables = async (instanceUUID: string) => {
  const headers = {
    accept: 'application/json',
    Authorization: `Basic ZHlkdTpLMEdGMUpSL2lYMWtwRWcrektTTForR1l6TVk=`,
  };
  const res: AxiosResponse = await axios.get(url_callbot + `/tts/voices/${instanceUUID}`, { headers });
  return res.data;
};

export const createCallBotConfigurationForDemo = async (data) => {
  const headers = {
    accept: 'application/json',
    Authorization: `Basic ZHlkdTpLMEdGMUpSL2lYMWtwRWcrektTTForR1l6TVk=`,
  };
  const params = {
    atriaServer: data.atriaServer,
    botUUID: data.botUUID,
    instanceName: data.instanceName,
    consultationSpace: data.consultationSpace,
    language: data.language,
  };
  const res: AxiosResponse = await axios.post(url_callbot + `/instances/createDemo`, data, { headers, params });
  return res.data;
};

export const deleteCallBotConfiguration = async (instanceUUID: string) => {
  const headers = {
    accept: 'application/json',
    Authorization: `Basic ZHlkdTpLMEdGMUpSL2lYMWtwRWcrektTTForR1l6TVk=`,
  };
  const res: AxiosResponse = await axios.delete(url_callbot + `/instances/${instanceUUID}`, { headers });
  return res.data;
};

export const generateTts = async (
  instanceUUID: string,
  voice: string,
  content: string,
  provider: string,
  language: string
) => {
  const headers = {
    accept: 'application/octet-stream',
    Authorization: `Basic ZHlkdTpLMEdGMUpSL2lYMWtwRWcrektTTForR1l6TVk=`,
  };
  const params = {
    provider: provider,
    language: language,
    voice: voice,
    content: content,
  };
  const responseType = 'blob';
  const res: AxiosResponse = await axios.post(url_callbot + `/tts/generate/${instanceUUID}`, '', {
    headers,
    params,
    responseType,
  });
  return res.data;
};

export const createRagConfig = async (data: object, token: string) => {
  const headers = {
    accept: 'application/json',
    servercode: serverCode,
    authorization: 'Bearer ' + token,
  };
  const res: AxiosResponse = await axios.post(getGatewayUrl() + '/api/dydubox_backend/rag', data, {
    headers,
  });
  return res.data;
};

export const getRagConfigurationsForBotUuid = async (botUUID: string, token: string) => {
  const headers = {
    accept: 'application/json',
    servercode: serverCode,
    authorization: 'Bearer ' + token,
  };
  const res: AxiosResponse = await axios.get(getGatewayUrl() + `/api/dydubox_backend/rag/bot/${botUUID}`, {
    headers,
  });
  return res.data;
};

export const deleteRagConfiguration = async (ragUUID: string | undefined, token: string) => {
  const headers = {
    accept: 'application/json',
    servercode: serverCode,
    authorization: 'Bearer ' + token,
  };
  const res: AxiosResponse = await axios.delete(getGatewayUrl() + `/api/dydubox_backend/rag/${ragUUID}`, {
    headers,
  });
  return res.data;
};

export const deleteRagConfigurationSource = async (
  ragUUID: string | undefined,
  sourceUUID: string | undefined,
  token: string
) => {
  const headers = {
    accept: 'application/json',
    servercode: serverCode,
    authorization: 'Bearer ' + token,
  };
  const res: AxiosResponse = await axios.delete(getGatewayUrl() + `/api/dydubox_backend/rag/${ragUUID}/${sourceUUID}`, {
    headers,
  });
  return res.data;
};

export const addRagConfigurationSourceFile = async (
  ragUUID: string | undefined,
  data: string,
  token: string,
  onProgress: (progress: number) => void
) => {
  const headers = {
    accept: '*/*',
    'content-type': 'multipart/form-data',
    servercode: serverCode,
    authorization: 'Bearer ' + token,
  };
  const formData = new FormData();
  formData.append('file', data);
  const config = {
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      onProgress && onProgress(percentCompleted);
    },
    headers,
  };
  const res: AxiosResponse = await axios.post(
    getGatewayUrl() + `/api/dydubox_backend/rag/${ragUUID}/file`,
    formData,
    config
  );
  return res.data;
};

export const addRagConfigurationSharePoint = async (ragUUID: string | undefined, data: object, token: string) => {
  const headers = {
    accept: 'application/json',
    servercode: serverCode,
    authorization: 'Bearer ' + token,
  };
  const res: AxiosResponse = await axios.post(
    getGatewayUrl() + `/api/dydubox_backend/rag/${ragUUID}/sharepoint`,
    data,
    {
      headers,
    }
  );
  return res.data;
};

export const updateRagConfigurationSharepoint = async (ragUUID: string | undefined, data: object, token: string) => {
  const headers = {
    accept: 'application/json',
    servercode: serverCode,
    authorization: 'Bearer ' + token,
  };
  const res: AxiosResponse = await axios.put(getGatewayUrl() + `/api/dydubox_backend/rag/${ragUUID}/sharepoint`, data, {
    headers,
  });
  return res.data;
};

export const addRagConfigurationWebSite = async (ragUUID: string | undefined, data: object, token: string) => {
  const headers = {
    accept: 'application/json',
    servercode: serverCode,
    authorization: 'Bearer ' + token,
  };
  const res: AxiosResponse = await axios.post(getGatewayUrl() + `/api/dydubox_backend/rag/${ragUUID}/website`, data, {
    headers,
  });
  return res.data;
};

export const updateRagConfigurationWebsite = async (ragUUID: string | undefined, data: object, token: string) => {
  const headers = {
    accept: 'application/json',
    servercode: serverCode,
    authorization: 'Bearer ' + token,
  };
  const res: AxiosResponse = await axios.put(getGatewayUrl() + `/api/dydubox_backend/rag/${ragUUID}/website`, data, {
    headers,
  });
  return res.data;
};

export const downloadRagConfigurationFile = async (
  ragUUID: string | undefined,
  fileUUID: string | undefined,
  token: string
) => {
  const headers = {
    accept: 'application/json',
    servercode: serverCode,
    authorization: 'Bearer ' + token,
  };
  const res: AxiosResponse = await axios.get(getGatewayUrl() + `/api/dydubox_backend/rag/${ragUUID}/file/${fileUUID}`, {
    headers,
    responseType: 'blob',
  });
  return res.data;
};

export const indexRagConfiguration = async (ragUUID: string, token: string) => {
  const headers = {
    accept: 'application/json',
    servercode: serverCode,
    authorization: 'Bearer ' + token,
  };
  const res: AxiosResponse = await axios.post(
    getGatewayUrl() + `/api/dydubox_backend/rag/index/${ragUUID}`,
    {},
    { headers }
  );
  return res.data;
};

export const getRagStatus = async (ragUUID: string, source: string, token: string) => {
  const headers = {
    accept: 'application/json',
    servercode: serverCode,
    authorization: 'Bearer ' + token,
  };
  const params = {
    source,
  };
  const res: AxiosResponse = await axios.get(getGatewayUrl() + `/api/dydubox_backend/rag/${ragUUID}/status`, {
    headers,
    params,
  });
  return res.data;
};

export const updateCustomLLMConfiguration = async (
  customLLMToUpdate: API_BACKEND.Schemas.LLMCustomBotConfigurationDTO,
  token: string
) => {
  const headers = {
    accept: 'application/json',
    servercode: serverCode,
    authorization: 'Bearer ' + token,
  };

  let axiosInstance;
  if (customLLMToUpdate?.id) {
    axiosInstance = axios.put;
  } else {
    axiosInstance = axios.post;
  }

  const res: AxiosResponse = await axiosInstance(
    getGatewayUrl() + `/api/dydubox_backend/llm/configuration/${customLLMToUpdate.botUUID}`,
    customLLMToUpdate,
    {
      headers,
    }
  );
  return res.data;
};

export const removeCustomLLMConfiguration = async (botUUID: string | undefined, token: string) => {
  console.log('removeCustomLLMConfiguration(' + botUUID + ', ' + token + ')');
  if (!botUUID) {
    return null;
  }

  const headers = {
    accept: 'application/json',
    servercode: serverCode,
    authorization: 'Bearer ' + token,
  };

  const res = await axios.delete(getGatewayUrl() + `/api/dydubox_backend/llm/configuration/${botUUID}`, {
    headers,
  });

  return res.data;
};

export const getCustomLLMForBot = async (botUUID: string, token: string) => {
  const headers = {
    accept: 'application/json',
    servercode: serverCode,
    authorization: 'Bearer ' + token,
  };

  const res: AxiosResponse = await axios.get(
    getGatewayUrl() + `/api/dydubox_backend/llm/configuration/bot/${botUUID}`,
    {
      headers,
    }
  );
  return res.data;
};

export const getAvailableLLMs = async (token: string) => {
  console.log(token);
  const headers = {
    accept: 'application/json',
    servercode: serverCode,
    authorization: 'Bearer ' + token,
  };

  const res: AxiosResponse = await axios.get(getGatewayUrl() + `/api/dydubox_backend/llm/configuration/available`, {
    headers,
  });
  return res.data;
};

export const getDefaultLLM = async (token: string) => {
  console.log(token);
  const headers = {
    accept: 'application/json',
    servercode: serverCode,
    authorization: 'Bearer ' + token,
  };

  const res: AxiosResponse = await axios.get(getGatewayUrl() + `/api/dydubox_backend/llm/configuration/default`, {
    headers,
  });
  return res.data;
};

export const generateExcel = async (ragUUID: string, token: string) => {
  const headers = {
    accept: 'application/json',
    servercode: serverCode,
    authorization: 'Bearer ' + token,
  };
  const res: AxiosResponse = await axios.post(
    getGatewayUrl() + `/api/dydubox_backend/rag/excel/${ragUUID}/generate`,
    {
      chunkSize: 512,
      language: 'French',
      themeList: [
        {
          theme: 'string',
          subThemes: ['string'],
        },
      ],
    },
    { headers }
  );
  return res.data;
};
