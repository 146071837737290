import Loader from 'src/Components/Loader/Loader';

const Auth = () => {
  console.log('auth displayed ' + window.location.href);

  return (
    <div className='auth-page'>
      <Loader loading={true} />
    </div>
  );
};

export default Auth;
