import * as Types from '../types';

import { action } from 'src/Tools/Redux';

/**
 * API Calls
 */
export const getImagesData = {
  success: (data) => action(Types.WEBSITE_LOCAL_STORAGE.UPDATE_IMAGES_DATA.SUCCESS, { data }),
};

export const updateWebsiteConfigurationContent = {
  success: (data) => action(Types.WEBSITE_LOCAL_STORAGE.UPDATE_CONTENT.SUCCESS, { data }),
};

export const getWebsiteConfigurationsList = {
  request: (botData) => action(Types.WEBSITE_CONFIGURATION.GET_ALL.REQUEST, { botData }),
  success: (data) => action(Types.WEBSITE_CONFIGURATION.GET_ALL.SUCCESS, { data }),
  failure: (error) => action(Types.WEBSITE_CONFIGURATION.GET_ALL.FAILURE, { error }),
};

export const getWebsiteConfiguration = {
  request: (configId: string, token: string) =>
    action(Types.WEBSITE_CONFIGURATION.GET_ONE.REQUEST, { configId, token }),
  success: (data) => action(Types.WEBSITE_CONFIGURATION.GET_ONE.SUCCESS, { data }),
  failure: (error) => action(Types.WEBSITE_CONFIGURATION.GET_ONE.FAILURE, { error }),
};

export const createWebsiteConfiguration = {
  request: (data: { botUUID: string; modifiedBy: string; name: string; type: string }) =>
    action(Types.WEBSITE_CONFIGURATION.CREATE_ONE.REQUEST, { data }),
  success: (data) => action(Types.WEBSITE_CONFIGURATION.CREATE_ONE.SUCCESS, { data }),
  failure: (error) => action(Types.WEBSITE_CONFIGURATION.CREATE_ONE.FAILURE, { error }),
};

export const updateWebsiteConfiguration = {
  request: (
    config: Models.WebsiteConfiguration,
    options: {
      redirect?: boolean;
      publish?: boolean;
      uploadAvatar?: boolean;
      env?: string;
      version?: string;
      file?: any;
      type?: string;
    },
    token: string
  ) => action(Types.WEBSITE_CONFIGURATION.UPDATE_ONE.REQUEST, { config, options, token }),
  success: (data) => action(Types.WEBSITE_CONFIGURATION.UPDATE_ONE.SUCCESS, { data }),
  failure: (error) => action(Types.WEBSITE_CONFIGURATION.UPDATE_ONE.FAILURE, { error }),
};

export const updateWebsiteConfigurationName = {
  request: (name: string, configId: string) =>
    action(Types.WEBSITE_CONFIGURATION.UPDATE_NAME.REQUEST, { name, configId }),
  success: (data) => action(Types.WEBSITE_CONFIGURATION.UPDATE_NAME.SUCCESS, { data }),
  failure: (error) => action(Types.WEBSITE_CONFIGURATION.UPDATE_NAME.FAILURE, { error }),
};

export const updateWebsiteConfigurationSlug = {
  request: (slug: string, configId: string) =>
    action(Types.WEBSITE_CONFIGURATION.UPDATE_SLUG.REQUEST, { slug, configId }),
  success: (data) => action(Types.WEBSITE_CONFIGURATION.UPDATE_SLUG.SUCCESS, { data }),
  failure: (error) => action(Types.WEBSITE_CONFIGURATION.UPDATE_SLUG.FAILURE, { error }),
};

export const importWebsiteConfiguration = {
  request: (type: string, configName: string, configZip: File, configUUID?: string | null) =>
    action(Types.WEBSITE_CONFIGURATION.IMPORT_ONE.REQUEST, { type, configName, configZip, configUUID }),
  success: (data) => action(Types.WEBSITE_CONFIGURATION.IMPORT_ONE.SUCCESS, { data }),
  failure: (error) => action(Types.WEBSITE_CONFIGURATION.IMPORT_ONE.FAILURE, { error }),
};

export const deleteWebsiteConfiguration = {
  request: (configId: string) => action(Types.WEBSITE_CONFIGURATION.DELETE_ONE.REQUEST, { configId }),
  success: (configId: string) => action(Types.WEBSITE_CONFIGURATION.DELETE_ONE.SUCCESS, { configId }),
  failure: (error) => action(Types.WEBSITE_CONFIGURATION.DELETE_ONE.FAILURE, { error }),
};

export const publishWebsiteConfiguration = {
  request: (config: Models.WebsiteConfiguration | string, env: string, version: string) =>
    action(Types.WEBSITE_CONFIGURATION.PUBLISH_ONE.REQUEST, { config, env, version }),
  success: (config?: Models.WebsiteConfiguration, configList?: Models.WebsiteConfiguration[]) =>
    action(Types.WEBSITE_CONFIGURATION.PUBLISH_ONE.SUCCESS, { config, configList }),
  failure: (error) => action(Types.WEBSITE_CONFIGURATION.PUBLISH_ONE.FAILURE, { error }),
};

export const restorePublishedConfiguration = {
  request: (configId: string, publicationId: string) =>
    action(Types.WEBSITE_CONFIGURATION.RESTORE_ONE.REQUEST, { configId, publicationId }),
  success: (config?: Models.WebsiteConfiguration, configList?: Models.WebsiteConfiguration[]) =>
    action(Types.WEBSITE_CONFIGURATION.RESTORE_ONE.SUCCESS, { config, configList }),
  failure: (error) => action(Types.WEBSITE_CONFIGURATION.RESTORE_ONE.FAILURE, { error }),
};

export const uploadWebsiteAvatar = {
  request: (file: File, avatarType: string) =>
    action(Types.WEBSITE_CONFIGURATION.UPLOAD_AVATAR.REQUEST, { file, avatarType }),
  success: () => action(Types.WEBSITE_CONFIGURATION.UPLOAD_AVATAR.SUCCESS),
  failure: (error) => action(Types.WEBSITE_CONFIGURATION.UPLOAD_AVATAR.FAILURE, { error }),
};

export const getWebsiteConfigurationZip = {
  request: (envType: string, configId?: string) =>
    action(Types.WEBSITE_CONFIGURATION.ZIP.REQUEST, { envType, configId }),
  success: () => action(Types.WEBSITE_CONFIGURATION.ZIP.SUCCESS),
  failure: (error) => action(Types.WEBSITE_CONFIGURATION.ZIP.FAILURE, { error }),
};

export const uploadTranslationExcel = {
  request: (configId: string, translationFile: File, lang?: string) =>
    action(Types.TRANSLATION.UPLOAD_EXCEL.REQUEST, { configId, lang, translationFile }),
  success: () => action(Types.TRANSLATION.UPLOAD_EXCEL.SUCCESS),
  failure: (error: any) => action(Types.TRANSLATION.UPLOAD_EXCEL.FAILURE, { error }),
};

export const uploadTranslationJson = {
  request: (configId: string, translation: any, lang?: string, replaceIfExist?: boolean) =>
    action(Types.TRANSLATION.UPLOAD_JSON.REQUEST, { configId, lang, translation, replaceIfExist }),
  success: () => action(Types.TRANSLATION.UPLOAD_JSON.SUCCESS),
  failure: (error: any) => action(Types.TRANSLATION.UPLOAD_JSON.FAILURE, { error }),
};

export const downloadTranslation = {
  request: (configId: string) => action(Types.TRANSLATION.DOWNLOAD.REQUEST, { configId }),
  success: () => action(Types.TRANSLATION.DOWNLOAD.SUCCESS),
  failure: (error: any) => action(Types.TRANSLATION.DOWNLOAD.FAILURE, { error }),
};
