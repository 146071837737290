import Message from '../../Components/Message/Message';
import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 15px 20px 0;
  width: 100%;
`;

export const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: calc(55px + 3rem);
  height: 60rem;
  width: 600px;
  overflow: hidden;

  @media screen and (max-height: 800px) {
    height: 50rem;
  }

  iframe {
    height: 100%;
    width: 100%;
    max-width: 400px;
    border-radius: 1rem;
  }
`;

export const OidcWarn = styled(Message)`
  margin-bottom: 10px;
  margin-top: 4px;
`;
