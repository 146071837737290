import { useState } from 'react';
import Icon from '../../../../../Components/Icon/Icon';
import { generateTts } from '../../../../../Api/Tools/Api';
import { toastError, toastNoContent } from '../../../../../Tools/Toast';
import { useTranslation } from 'react-i18next';

interface AudioPlayerProps {
  content: string;
  provider: string;
  instanceUuid: string;
  voice: string;
  language: string;
}
const AudioPlayer = ({ content, provider, instanceUuid, voice, language }: AudioPlayerProps) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const { t } = useTranslation();

  const generateAudio = () => {
    generateTts(instanceUuid, voice, content, provider, language)
      .then((res: any) => {
        if (res.size === 0) return toastError(t('edition.callbot.errorVoice'));
        const url = URL.createObjectURL(res);
        const audio = new Audio(url);
        audio?.play();
        setIsPlaying(true);
        if (audio)
          audio.onended = () => {
            setIsPlaying(false);
          };
      })
      .catch((error) => {
        setIsPlaying(false);
        console.log(error);
        toastError(t('edition.callbot.errorVoice'));
      });
  };
  const iconToDisplay = isPlaying ? 'pause' : 'play';
  return (
    <div className='audio-player'>
      <Icon
        icon={iconToDisplay}
        size={20}
        color='#3636B9'
        onClick={() => {
          if (!content) toastNoContent();
          else if (!isPlaying) {
            generateAudio();
          }
        }}
      />
    </div>
  );
};

export default AudioPlayer;
