import { APP_PATH, CONFIG_TYPES } from 'src/Tools/Constants';
import { OptionContainer, StatusWrapper, TableIcon } from '../Style';

import Button from 'src/Components/Button/Button';
import { ConfigurationsContainerProps } from '../Containers/ConfigurationsContainer';
import ConnectorActionsContainer from '../Containers/ConnectorActionsContainer';
import InfoTooltip from './InfoTooltip';
import PanelAction from './PanelAction';
import { ReactNode, useState } from 'react';
import Tag from 'src/Components/Tag/Tag';
import TypeList from './TypeList';
import WebsiteActionsContainer from '../Containers/WebsiteActionsContainer';
import imageMeta from 'src/Assets/Images/logo-meta.png';
import imageTeams from 'src/Assets/Images/logo-teams.png';
import imageWebsite from 'src/Assets/Images/logo-website.png';
import imageCallbot from 'src/Assets/Images/logo-callbot.png';
import { sortArrayByDate } from 'src/Tools/Date';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { hasBeenPublishedIn } from '../../../Tools/DyduboxConfigurationUtils';
import { useCallBotConfigInfo } from '../../../Contexts/CallBotContext';
import { deleteCallBotConfiguration } from '../../../Api/Tools/Api';
import Icon from '../../../Components/Icon/Icon';
import { IconTrigger } from '../../ExternalContents/Style';
import ModalDelete from '../../ExternalContents/Components/Rag/ModalDelete';
import { toastError, toastSuccess } from '../../../Tools/Toast';

const Configurations = ({
  importConfig,
  openConfig,
  configurationsList,
  formatDateUseLocale,
}: ConfigurationsContainerProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { callBotConfigurations, setCallBotConfigurations } = useCallBotConfigInfo();
  const [visibleDelete, setVisibleDelete] = useState<boolean>(false);
  const [idToDelete, setIdToDelete] = useState<string>('');

  const handleCreateConfig = () => navigate(APP_PATH.NEW_CONFIG);

  const imageTypes = {
    teams: imageTeams,
    website: imageWebsite,
    customer: imageWebsite,
    meta: imageMeta,
    callbot: imageCallbot,
  };

  const columns = [
    {
      id: 'platform',
      title: t('general.platform'),
    },
    {
      id: 'name',
      title: t('configurations.configuration_name'),
    },
    {
      id: 'modifiedBy',
      title: t('general.updated_by'),
    },
    {
      id: 'updatedAt',
      title: t('configurations.date_of_last_update'),
      body: (config) => formatDateUseLocale(config.updatedAt),
    },
    {
      id: 'status',
      title: t('general.status'),
    },
    {
      id: 'actions',
      title: t('general.actions'),
    },
  ];

  const callBotColumns = [
    {
      id: 'platform',
      title: t('general.platform'),
    },
    {
      id: 'name',
      title: t('configurations.configuration_name'),
    },
    {
      id: 'phoneLine',
      title: t('general.phone'),
    },
    {
      id: 'delete',
      title: t('general.delete'),
      body: (config) => {
        const handleDeleteDemo = () => {
          deleteCallBotConfiguration(idToDelete)
            .then(() => {
              setCallBotConfigurations((prevConfigs) => prevConfigs.filter((item) => item.uuid !== idToDelete));
            })
            .then(() => {
              toastSuccess();
            })
            .catch((error) => {
              toastError(error);
            });
        };
        return (
          <>
            <IconTrigger
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <Icon
                color='red'
                icon='trash'
                size={18}
                onClick={() => {
                  setIdToDelete(config.uuid);
                  setVisibleDelete(true);
                }}
              />
            </IconTrigger>
            <ModalDelete
              visible={visibleDelete}
              setVisible={setVisibleDelete}
              onHide={() => setVisibleDelete(false)}
              handleDelete={handleDeleteDemo}
            />
          </>
        );
      },
    },
  ];
  const renderStatusTags = (config) => {
    const hasBeenPublishedInPreprod: boolean = hasBeenPublishedIn(config?.publications, 'PREPROD');
    const hasBeenPublishedInProd: boolean = hasBeenPublishedIn(config?.publications, 'PROD');

    return [CONFIG_TYPES.WEBSITE, CONFIG_TYPES.CUSTOMER].includes(config.type) ? (
      <StatusWrapper>
        {!hasBeenPublishedInPreprod && !hasBeenPublishedInProd && (
          <Tag value={t('general.publish_status.not_published')} />
        )}
        {hasBeenPublishedInPreprod && (
          <Tag
            hoverComponent={{
              element: <InfoTooltip config={config} formatDateUseLocale={formatDateUseLocale} />,
            }}
            value={t('general.publish_status.preprod')}
            severity='warning'
          />
        )}
        {hasBeenPublishedInProd && <Tag value={t('general.publish_status.prod')} severity='success' />}
      </StatusWrapper>
    ) : null;
  };

  const renderActionContainer = (config) => {
    switch (config?.type) {
      case CONFIG_TYPES.WEBSITE:
      case CONFIG_TYPES.CUSTOMER:
        return <WebsiteActionsContainer config={config} />;
      case CONFIG_TYPES.TEAMS:
      case CONFIG_TYPES.META:
        return <ConnectorActionsContainer config={config} />;
      default:
        return null;
    }
  };

  const data = configurationsList?.map((config) => {
    return {
      ...config,
      ...{
        platform: config?.type && <TableIcon src={imageTypes[config?.type]} title={config?.type} />,
        status: renderStatusTags(config),
        actions: renderActionContainer(config),
      },
    };
  });

  const callBotConfigs = callBotConfigurations?.map((config) => {
    return {
      ...config,
      ...{
        platform: config?.type && <TableIcon src={imageTypes[config?.type]} title={config?.type} />,
        id: config?.uuid,
        phoneLine: (config?.phoneLine.match(/.{1,2}/g) || []).join(' '),
        actions: renderActionContainer(config),
      },
    };
  });

  const sortedData = sortArrayByDate(data, 'updatedAt');

  const createdConfigs = sortedData?.filter((config) => config.type !== CONFIG_TYPES.CUSTOMER);

  const importedConfigs = sortedData?.filter((config) => config.type === CONFIG_TYPES.CUSTOMER);

  const renderList = (
    configs: Models.ConfigTypes[],
    columns: any,
    title: string,
    subtitle: string,
    action: ReactNode,
    emptyListAction: ReactNode
  ) =>
    configs.length > 0 ? (
      <TypeList
        data={configs}
        columns={columns}
        openConfig={openConfig}
        title={title}
        subtitle={subtitle}
        action={action}
      />
    ) : (
      emptyListAction
    );

  const importedConfigsRender = renderList(
    importedConfigs,
    columns,
    t('configurations.imported_configs.title'),
    t('configurations.imported_configs.subtitle'),
    <Button
      label={t('configurations.import_config')}
      className='p-button-text'
      icon='icon-upload'
      onClick={importConfig}
    />,
    <PanelAction type='import' importConfig={importConfig} />
  );
  const createdConfigsRender = renderList(
    createdConfigs,
    columns,
    t('configurations.native_configs.title'),
    t('configurations.native_configs.subtitle'),
    <Button
      label={t('configurations.create_config')}
      className='p-button-text'
      icon='icon-add'
      onClick={handleCreateConfig}
    />,
    <PanelAction type='newConfig' />
  );

  const callBotConfigsRender = renderList(
    callBotConfigs,
    callBotColumns,
    t('configurations.callbot_configs.title'),
    t('configurations.callbot_configs.subtitle'),
    <Button
      label={t('configurations.create_config')}
      className='p-button-text'
      icon='icon-add'
      onClick={handleCreateConfig}
    />,
    null
  );

  return (
    <>
      <OptionContainer
        createdConfigs={createdConfigs}
        callBotConfigs={callBotConfigs}
        importedConfigs={importedConfigs}
      >
        {createdConfigs.length === 0 && callBotConfigs.length === 0 && importedConfigs.length > 0 ? (
          <>
            {importedConfigsRender}
            {createdConfigsRender}
            {callBotConfigsRender}
          </>
        ) : callBotConfigs.length > 0 && createdConfigs.length <= 0 ? (
          <>
            {callBotConfigsRender}
            {createdConfigsRender}
            {importedConfigsRender}
          </>
        ) : (
          <>
            {createdConfigsRender}
            {callBotConfigsRender}
            {importedConfigsRender}
          </>
        )}
      </OptionContainer>
    </>
  );
};

export default Configurations;
