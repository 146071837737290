import { APP_PATH, CONFIG_TYPES } from 'src/Tools/Constants';
import {
  CONNECTOR_CONFIGURATION,
  META_CONFIGURATION,
  META_LINKED_OBJECT,
  WEBSITE_CONFIGURATION,
} from 'src/Redux/Configurations/types';
import { Control, FieldErrors, useForm, UseFormGetValues, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { PromptActionsProps, usePrompt } from 'src/Hooks/useBlocker';
import { closeModal, openModal } from 'src/Redux/Modal/actions';
import {
  getConnectorConfiguration,
  updateConnectorConfiguration,
} from 'src/Redux/Configurations/actions/connectorActions';
import { getMessageError, getSelectedConfig } from 'src/Redux/Configurations/selectors';
import { getWebsiteConfiguration, updateWebsiteConfiguration } from 'src/Redux/Configurations/actions/websiteActions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Actions from 'src/Components/Actions/Actions';
import ConnectorContainer from './Connector/ConnectorContainer';
import { CallBotEditorWrapper, ErrorWrapper } from '../Style';
import Loader from 'src/Components/Loader/Loader';
import Message from 'src/Components/Message/Message';
import Stepper from '../Components/Stepper';
import WebsiteContainer from './Website/WebsiteContainer';
import useDocumentTitle from 'src/Hooks/useDocumentTitle';
import { useTranslation } from 'react-i18next';
import CallBotContainer from './CallBot/CallBotContainer';
import { useCallBotConfigInfo } from '../../../Contexts/CallBotContext';
import { useUserContext } from '../../../Contexts/UserContext';
import { getToken } from 'src/Api/Tools/Api';

export type setCurrentStepProps = 'next' | 'previous' | number;

export interface stepsInterface {
  label: string;
  disabled?: boolean;
  body: any;
}

export interface formInterface {
  openModal: (title: string, body: any, options?: any) => void;
  closeModal: () => void;
  errors: FieldErrors<any>;
  isValid: boolean;
  control: Control<any, any>;
  watch: UseFormWatch<any>;
  trigger: any;
  selectedConfig: Models.ConfigTypes;
  handleFormSubmit: (callback?: any) => void;
  getValues: UseFormGetValues<any>;
  setValue: UseFormSetValue<Models.ConfigTypes | any>; // REFACTO NEED TO POPULATE WITH MODELS
  setCurrentStep: (info: setCurrentStepProps) => void;
  currentStep: number;
  setStepperConfig: any;
}

const EditionContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, type } = useParams<{ id: string; type: string }>();
  const { currentNavigationStep } = useUserContext();
  const [currentStep, setCurrentStep] = useState(currentNavigationStep);
  const [stepperConfig, setStepperConfig] = useState<stepsInterface[]>([]);
  const selectedConfig = useSelector(getSelectedConfig);
  const messageError = useSelector(getMessageError);
  const { callBotConfigurations, setSelectedCallBotConfig, selectedCallBotConfig } = useCallBotConfigInfo();
  const { logout } = useUserContext();

  useDocumentTitle(`${t('general.pages.main')} - ${t('general.pages.edition')} - ${selectedConfig?.name}`);

  useEffect(() => {
    id !== selectedConfig?.id && getConfiguration();
  }, [id]);

  useEffect(() => {
    if (id && type === CONFIG_TYPES.CALLBOT) {
      const selectedConfig = callBotConfigurations.find((config: any) => config.uuid === id);
      setSelectedCallBotConfig(selectedConfig);
    }
  }, [callBotConfigurations, id]);

  const {
    control,
    formState: { errors, isValid, isDirty, isSubmitted },
    watch,
    setValue,
    getValues,
    reset,
    handleSubmit,
    trigger,
  } = useForm({
    defaultValues: selectedConfig,
    mode: 'onChange',
  });

  const mainColorModified =
    selectedConfig?.type === CONFIG_TYPES.WEBSITE &&
    selectedConfig?.theme.palette.primary.main !== watch('theme.palette.primary.main');

  useEffect(() => {
    reset(selectedConfig);
  }, [selectedConfig]);

  const getConfiguration = () => {
    switch (type) {
      case CONFIG_TYPES.WEBSITE:
      case CONFIG_TYPES.CUSTOMER:
        return id && dispatch(getWebsiteConfiguration.request(id, getToken()));
      case CONFIG_TYPES.TEAMS:
      case CONFIG_TYPES.META:
        return id && dispatch(getConnectorConfiguration.request(id, type, getToken()));
    }
  };

  const handleSave = (redirect?: boolean) => {
    const data = getValues();
    switch (data.type) {
      case CONFIG_TYPES.WEBSITE:
      case CONFIG_TYPES.CUSTOMER:
        return dispatch(updateWebsiteConfiguration.request(data, { redirect }, getToken()));
      case CONFIG_TYPES.TEAMS:
      case CONFIG_TYPES.META:
        return dispatch(updateConnectorConfiguration.request(data.type, data, { redirect }, getToken()));
      default:
        throw 'Config type not yet suported';
    }
  };

  const handleCloseModal = () => dispatch(closeModal());

  const handleOpenModal = (title: string, body: any, options) => dispatch(openModal({ title, body, options }));

  const handleFormSubmit = (callback?: any) => handleSubmit(callback)();

  const handleSaveCallBotConfig = () => {
    // Add here method post to save callbot config and redirect to configuration list
    setSelectedCallBotConfig(null);
    navigate(APP_PATH.CONFIGURATIONS);
  };

  const handleSubmitSaveAndQuit = (redirect?: boolean) => handleFormSubmit(() => handleSave(redirect));

  const handleSetStepperConfig = (steps: stepsInterface[]) => setStepperConfig(steps);

  const handleSetCurrentStep = (info: setCurrentStepProps) => {
    switch (info) {
      case 'next':
        if (currentStep < stepperConfig.length - 1) {
          return setCurrentStep(currentStep + 1);
        }
        break;
      case 'previous':
        if (currentStep > 0) {
          return setCurrentStep(currentStep - 1);
        }
        break;
      default:
        return setCurrentStep(info);
    }
  };

  const props: formInterface = {
    errors,
    isValid,
    control,
    watch,
    setValue,
    getValues,
    handleFormSubmit,
    trigger,
    selectedConfig,
    openModal: handleOpenModal,
    closeModal: handleCloseModal,
    setCurrentStep: handleSetCurrentStep,
    setStepperConfig: handleSetStepperConfig,
    currentStep: currentStep,
  };

  const promptActions: PromptActionsProps[] = [
    {
      label: t('edition.modal.saveandquit.actions.leave'),
      className: 'p-button-text p-button-secondary',
      unblock: true,
      onClick: () => navigate(APP_PATH.CONFIGURATIONS),
    },
    {
      label: t('edition.modal.saveandquit.actions.saveandquit'),
      icon: 'icon-check',
      unblock: true,
      onClick: () => handleSubmitSaveAndQuit(true),
    },
  ];

  // This is te prompte trick. Once form is dirty when value change, you need to use
  // handleFormSubmit method with the callback as param to specify that hook form as been submitted
  // And release the prompte that block navigation
  usePrompt(
    t('edition.modal.saveandquit.title'),
    t('edition.modal.saveandquit.description'),
    (isDirty || mainColorModified) && !isSubmitted,
    promptActions
  );

  const renderContainerType = () => {
    switch (selectedConfig?.type) {
      case CONFIG_TYPES.WEBSITE:
      case CONFIG_TYPES.CUSTOMER:
        return <WebsiteContainer {...props} />;
      case CONFIG_TYPES.TEAMS:
      case CONFIG_TYPES.META:
        return <ConnectorContainer {...props} />;
      default:
        return;
    }
  };

  const renderContent = () => {
    if (selectedConfig?.id) {
      return (
        <>
          {renderContainerType()}
          <Stepper
            currentStep={currentStep}
            setCurrentStep={handleSetCurrentStep}
            saveAndQuit={() => handleSubmitSaveAndQuit(true)}
            steps={stepperConfig}
          />
        </>
      );
    } else if (selectedCallBotConfig) {
      return (
        <>
          <CallBotEditorWrapper>
            <CallBotContainer {...props} />
          </CallBotEditorWrapper>
          {/*method saveAndQuit is not implemented yet, need to be an API call to save the changes in the callbot config*/}
          <Stepper
            currentStep={currentStep}
            setCurrentStep={handleSetCurrentStep}
            saveAndQuit={handleSaveCallBotConfig}
            steps={stepperConfig}
          />
        </>
      );
    }
  };

  useEffect(() => {
    if (messageError?.endsWith('401')) {
      logout();
    }
  }, [messageError]);

  const renderErrorMessage = () =>
    messageError &&
    !messageError.endsWith('401') && (
      <ErrorWrapper>
        <Message severity='error' text={messageError} />
        <Actions
          actions={[
            {
              label: t('general.configuration_list'),
              icon: 'icon-arrow-left',
              onClick: () => navigate(APP_PATH.CONFIGURATIONS),
            },
          ]}
        />
      </ErrorWrapper>
    );

  const renderLoader = () => (
    <Loader
      actions={[
        WEBSITE_CONFIGURATION.GET_ONE.REQUEST,
        WEBSITE_CONFIGURATION.UPDATE_ONE.REQUEST,
        WEBSITE_CONFIGURATION.UPLOAD_AVATAR.REQUEST,
        WEBSITE_CONFIGURATION.ZIP.REQUEST,
        CONNECTOR_CONFIGURATION.GET_ONE.REQUEST,
        CONNECTOR_CONFIGURATION.UPDATE_ONE.REQUEST,
        CONNECTOR_CONFIGURATION.UPLOAD_AVATAR.REQUEST,
        CONNECTOR_CONFIGURATION.ZIP.REQUEST,
        META_CONFIGURATION.WEBHOOK.REQUEST,
        META_CONFIGURATION.CREDENTIALS.REQUEST,
        META_LINKED_OBJECT.CREATE_ONE.REQUEST,
      ]}
    />
  );

  return (
    <div className='edition-container'>
      {renderLoader()}
      {renderContent()}
      {renderErrorMessage()}
    </div>
  );
};

export default EditionContainer;
