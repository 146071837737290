import * as Actions from 'src/Redux/Configurations/actions/connectorActions';
import * as Constants from 'src/Tools/Constants';
import * as Types from '../types';

import { all, call, put, select, takeLatest } from 'typed-redux-saga';
import { closeModal, openModal } from 'src/Redux/Modal/actions';
import { getConsultationSpace, getCurrentBot, getLanguages } from 'src/Redux/Bot/selectors';
import { getUserHost, getUserName } from 'src/Redux/User/selectors';
import { toastError, toastSuccess } from 'src/Tools/Toast';

import { getClientApi } from 'src/Api/Tools/Api';
import { getSelectedWebhookSubscriptions } from 'src/Redux/Configurations/selectors';
import i18next from 'src/Services/i18n';
import { push } from '@lagunovsky/redux-react-router';
import { ApiProps } from 'src/Api';

/**
 * API CALLS
 */

/**
 * Get Connector configurations list
 * @param botData
 * @returns
 */
function* getConnectorConfigurationsList({ payload: { botData } }) {
  try {
    const Client: ApiProps = yield call(getClientApi);
    const { data } = yield* call(Client.connector.getConnectorConfigurationsList, botData.botUUID);
    yield put(Actions.getConnectorConfigurationsList.success(data));
  } catch (error: any) {
    yield put(Actions.getConnectorConfigurationsList.failure(error));
    toastError(error);
  }
}

/**
 * Get a Connector configuration by type
 * @param configId
 * @param connectorType
 * @returns
 */
function* getConnectorConfiguration({ payload: { configId, connectorType } }) {
  try {
    const Client: ApiProps = yield call(getClientApi);
    const { data }: { data: any } = yield* call(Client.connector.getConnectorConfiguration, configId, connectorType);
    const endpoint = yield* call(Client.connector.getBaseUrl);

    const formatedData =
      data?.type === Constants.CONFIG_TYPES.TEAMS
        ? {
            ...data,
            avatars: data.info.connectorAvatars.map((avatar) => {
              return {
                ...avatar,
                avatarByte: `data:image/png;base64,${avatar.avatarByte}`,
              };
            }),
            endpoint: `${endpoint}/${connectorType}/messages`,
          }
        : {
            ...data,
            endpoint: `${endpoint}/${connectorType}/${data.id}/webhook`,
          };

    yield put(Actions.getConnectorConfiguration.success(formatedData));
  } catch (error: any) {
    yield put(Actions.getConnectorConfiguration.failure(error));
    toastError(error);
  }
}

/**
 * Update a Connector configuration
 * @param connectorType
 * @param type
 * @param config
 * @returns
 */
function* updateConnectorConfiguration({ payload: { connectorType, config, options } }) {
  try {
    const Client: ApiProps = yield call(getClientApi);
    const userName = yield select(getUserName);
    const languages = yield select(getLanguages);
    const currentConsultationSpace = yield select(getConsultationSpace);
    const currentBot = yield select(getCurrentBot);
    const { botUUID } = currentBot;

    const configData = {
      ...config,
      botUUID,
      consultationSpace: config.consultationSpace || currentConsultationSpace?.[0]?.name,
      language: config.language || languages[0].name,
      modifiedBy: userName,
    };

    const { data: updateConfig }: any = yield* call(
      Client.connector.updateConnectorConfiguration,
      configData,
      connectorType
    );

    toastSuccess();

    yield put(Actions.updateConnectorConfiguration.success(updateConfig));

    if (options?.redirect) {
      yield put(push(Constants.APP_PATH.CONFIGURATIONS));
    }
    if (options.downloadConfig) {
      yield put(Actions.getTeamsConfigurationZip.request(config));
    }
  } catch (error: any) {
    yield put(Actions.updateConnectorConfiguration.failure(error));
    toastError(error);
  }
}

/**
 * Update Connector configuration Name
 * @param name
 * @param configId
 * @returns
 */
function* updateConnectorConfigurationName({ payload: { name, configId } }) {
  try {
    const Client: ApiProps = yield call(getClientApi);
    const { data } = yield* call(Client.connector.updateConnectorConfigurationName, configId, name);

    toastSuccess();

    yield put(Actions.updateConnectorConfigurationName.success(data));
    yield put(closeModal());
  } catch (error: any) {
    yield put(Actions.updateConnectorConfigurationName.failure(error));
    toastError(error);
  }
}

/**
 * Create Connector configuration
 * @param data
 * @returns
 */
function* createConnectorConfiguration({ payload: { data } }) {
  try {
    const Client: ApiProps = yield call(getClientApi);
    const host = yield select(getUserHost);
    const consultationSpaces = yield select(getConsultationSpace);
    const languages = yield select(getLanguages);

    const configData = {
      ...data,
      hostname: host,
      language: languages?.[0]?.name,
      consultationSpace: consultationSpaces?.[0]?.name,
    };

    const { data: newConfig }: any = yield* call(Client.connector.createConnectorConfiguration, configData, data.type);

    const formattedData = {
      ...newConfig,
      avatars: newConfig.info.connectorAvatars?.map((avatar) => ({
        ...avatar,
        avatarByte: `data:image/png;base64,${avatar.avatarByte}`,
      })),
    };

    yield put(Actions.createConnectorConfiguration.success(formattedData));
    yield put(closeModal());
    toastSuccess();
    yield put(push(`${Constants.APP_PATH.CONFIGURATIONS}/${data.type}/${formattedData.id}`));
  } catch (error) {
    yield put(Actions.createConnectorConfiguration.failure(error));
    toastError(error);
  }
}

/**
 * Delete Connector configuration
 * @param configId
 * @param connectorType
 * @returns
 */
function* deleteConnectorConfiguration({ payload: { configId, connectorType } }) {
  try {
    const Client: ApiProps = yield call(getClientApi);
    yield call(Client.connector.deleteConnectorConfiguration, configId, connectorType);
    yield put(Actions.deleteConnectorConfiguration.success(configId));
    yield put(closeModal());

    toastSuccess();

    yield put(Actions.deleteConnectorConfiguration.success(configId));
  } catch (error: any) {
    yield put(Actions.deleteConnectorConfiguration.failure(error));
    toastError(error);
  }
}

/**
 * Upload Connector configuration Avatar
 * @param configId
 * @param id
 * @param avatarType
 * @param connectorType
 * @returns
 */
function* uploadConnectorAvatar({ payload: { file, id, avatarType, connectorType } }) {
  try {
    const Client: ApiProps = yield call(getClientApi);
    const formData = new FormData();
    formData.append(Constants.FORMDATA_TYPES.AVATAR, file);

    const imageType = avatarType.toUpperCase();

    yield call(Client.connector.uploadConnectorAvatar, formData, id, imageType, connectorType);

    toastSuccess();

    yield put(Actions.uploadConnectorAvatar.success());
  } catch (error) {
    yield put(Actions.uploadConnectorAvatar.failure(error));
    toastError(error);
  }
}

/**
 * MICROSOFT TEAMS
 */

/**
 * Get (Microsoft) Teams configuration Zip
 * @param config
 * @returns
 */
function* getTeamsConfigurationZip({ payload: { config } }) {
  try {
    const Client: ApiProps = yield call(getClientApi);
    const { data } = yield* call(Client.connector.getTeamsConfigurationZip, config.info.microsoftAppId);

    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', `Configuration_${config?.type}.zip`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    toastSuccess();

    yield put(Actions.getTeamsConfigurationZip.success());
  } catch (error: any) {
    yield put(Actions.getTeamsConfigurationZip.failure(error));
    toastError(error);
  }
}

/**
 * META
 */

/**
 * Create (Meta) Webhook for page type
 * @param config
 * @returns
 */
function* createMetaWebhook({ payload: { connectorUUID, pageType, callbackModal } }) {
  try {
    const Client: ApiProps = yield call(getClientApi);
    const appWebhook = yield select(getSelectedWebhookSubscriptions);
    const typeWebhook = appWebhook?.find((webhook) => webhook?.object === pageType);

    let updatedWebhook;

    if (!typeWebhook?.isActive) {
      const { data } = yield* call(Client.connector.createMetaWebhookSubscription, connectorUUID, pageType);
      updatedWebhook = appWebhook.map((webhook) => (webhook.object === data.object ? data : webhook));
      toastSuccess(i18next.t('toast.webhook.create.success', { pageType }));
    }

    yield put(Actions.createMetaWebhook.success(updatedWebhook));
    yield put(openModal({ title: pageType, body: callbackModal }));
  } catch (error) {
    yield put(Actions.createMetaWebhook.failure(error));
    toastError(error);
  }
}

/**
 * Update (Meta) App Credentials
 * @param config
 * @returns
 */
function* updateMetaAppCredentials({ payload: { connectorUUID, credentials } }) {
  try {
    const Client: ApiProps = yield call(getClientApi);
    const { data } = yield* call(Client.connector.updateMetaAppCredentials, connectorUUID, credentials);
    yield put(closeModal());
    toastSuccess(i18next.t('toast.appCredentials.update.success'));
    yield put(Actions.updateMetaAppCredentials.success(data));
  } catch (error) {
    yield put(Actions.updateMetaAppCredentials.failure(error));
    toastError(error);
  }
}

/**
 * Create (Meta) LinkedObject
 * @param config
 * @returns
 */
function* createMetaLinkedObject({ payload: { connectorUUID, data } }) {
  try {
    const Client: ApiProps = yield call(getClientApi);
    const { data: linkedObject } = yield* call(Client.connector.createMetaLinkedObject, connectorUUID, data);

    toastSuccess();
    yield put(closeModal());

    yield put(Actions.createMetaLinkedObject.success(linkedObject));
  } catch (error) {
    yield put(Actions.createMetaLinkedObject.failure(error));
    toastError(error);
  }
}

/**
 * Update (Meta) LinkedObject
 * @param config
 * @returns
 */
function* updateMetaLinkedObject({ payload: { connectorUUID, linkedObjectUUID, data } }) {
  try {
    const Client: ApiProps = yield call(getClientApi);
    const { data: linkedObject } = yield* call(
      Client.connector.updateMetaLinkedObject,
      connectorUUID,
      linkedObjectUUID,
      data
    );

    toastSuccess();
    yield put(closeModal());

    yield put(Actions.updateMetaLinkedObject.success(linkedObject));
  } catch (error) {
    yield put(Actions.updateMetaLinkedObject.failure(error));
    toastError(error);
  }
}

/**
 * Delete (Meta) LinkedObject
 * @param config
 * @returns
 */
function* deleteMetaLinkedObject({ payload: { connectorUUID, linkedObjectUUID } }) {
  try {
    const Client: ApiProps = yield call(getClientApi);
    yield* call(Client.connector.deleteMetaLinkedObject, connectorUUID, linkedObjectUUID);

    toastSuccess();
    yield put(closeModal());

    yield put(Actions.deleteMetaLinkedObject.success(linkedObjectUUID));
  } catch (error) {
    yield put(Actions.deleteMetaLinkedObject.failure(error));
    toastError(error);
  }
}

/**
 * Chained Actions
 */

/**
 * Connector Sagas
 */
export default function* connectorSaga() {
  try {
    yield all([
      takeLatest(Types.CONNECTOR_CONFIGURATION.GET_ALL.REQUEST, getConnectorConfigurationsList),
      takeLatest(Types.CONNECTOR_CONFIGURATION.GET_ONE.REQUEST, getConnectorConfiguration),
      takeLatest(Types.CONNECTOR_CONFIGURATION.UPDATE_ONE.REQUEST, updateConnectorConfiguration),
      takeLatest(Types.CONNECTOR_CONFIGURATION.UPDATE_NAME.REQUEST, updateConnectorConfigurationName),
      takeLatest(Types.CONNECTOR_CONFIGURATION.CREATE_ONE.REQUEST, createConnectorConfiguration),
      takeLatest(Types.CONNECTOR_CONFIGURATION.DELETE_ONE.REQUEST, deleteConnectorConfiguration),
      takeLatest(Types.CONNECTOR_CONFIGURATION.UPLOAD_AVATAR.REQUEST, uploadConnectorAvatar),
      /** TEAMS */
      takeLatest(Types.CONNECTOR_CONFIGURATION.ZIP.REQUEST, getTeamsConfigurationZip),
      /** META */
      takeLatest(Types.META_CONFIGURATION.WEBHOOK.REQUEST, createMetaWebhook),
      takeLatest(Types.META_CONFIGURATION.CREDENTIALS.REQUEST, updateMetaAppCredentials),
      takeLatest(Types.META_LINKED_OBJECT.CREATE_ONE.REQUEST, createMetaLinkedObject),
      takeLatest(Types.META_LINKED_OBJECT.UPDATE_ONE.REQUEST, updateMetaLinkedObject),
      takeLatest(Types.META_LINKED_OBJECT.DELETE_ONE.REQUEST, deleteMetaLinkedObject),
      /** Chained Actions */
    ]);
  } catch (error: any) {
    toastError(error);
  }
}
