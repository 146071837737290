import { ReactNode, useEffect, useMemo } from 'react';
import { closeModal, openModal } from 'src/Redux/Modal/actions';
import { useDispatch, useSelector } from 'react-redux';

import { ActionProps } from 'src/Components/Actions/Actions';
import { CHATBOX_VERSION } from 'src/Tools/Constants';
import { WEBSITE_CONFIGURATION } from 'src/Redux/Configurations/types';
import WebsiteActions from '../Components/WebsiteActions';
import { deleteWebsiteConfiguration } from 'src/Redux/Configurations/actions/websiteActions';
import { getLoadingStatus } from 'src/Redux/Loading/selectors';
import { useTranslation } from 'react-i18next';
import { getLastPublicationOfType } from '../../../Tools/DyduboxConfigurationUtils';
import { useUserContext } from '../../../Contexts/UserContext';
import { getMessageError } from '../../../Redux/Configurations/selectors';

interface WebsiteActionsContainerProps {
  config: Models.WebsiteConfiguration;
  disabled?: boolean;
}
export interface WebsiteActionsProps {
  config: Models.WebsiteConfiguration;
  deleteModalActions: any[];
  hasVersionUpdate?: boolean;
  openModal: (title: string, body: ReactNode, actions?: any[]) => void;
}

const WebsiteActionsContainer = ({ config }: WebsiteActionsContainerProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => getLoadingStatus(state, WEBSITE_CONFIGURATION.DELETE_ONE.REQUEST));
  const { logout } = useUserContext();
  const messageError = useSelector(getMessageError);

  useEffect(() => {
    if (messageError?.endsWith('401')) {
      logout();
    }
  }, [messageError]);

  const handleOpenModal = (title: string, body: any, actions?: any[]) => dispatch(openModal({ title, body, actions }));

  const hasVersionUpdate = useMemo(() => {
    const lastPreprodPublication = getLastPublicationOfType(config?.publications, 'PREPROD');
    const lastProdPublication = getLastPublicationOfType(config?.publications, 'PROD');

    const prodAvailable = lastProdPublication?.version !== CHATBOX_VERSION;
    const preprodAvailable = lastPreprodPublication?.version !== CHATBOX_VERSION;
    return CHATBOX_VERSION && (prodAvailable || preprodAvailable);
  }, [config]);

  const handleDeleteConfig = () => config.id && dispatch(deleteWebsiteConfiguration.request(config.id));

  const deleteModalActions: ActionProps[] = [
    {
      label: t('general.cancel'),
      className: 'p-button-text p-button-secondary',
      icon: 'icon-close',
      onClick: () => dispatch(closeModal()),
    },
    {
      label: t('general.delete'),
      icon: 'icon-trash',
      onClick: handleDeleteConfig,
      loading: isLoading,
      className: 'p-button-danger',
    },
  ];

  const props: WebsiteActionsProps = {
    config,
    deleteModalActions,
    hasVersionUpdate,
    openModal: handleOpenModal,
  };

  return <WebsiteActions {...props} />;
};

export default WebsiteActionsContainer;
