import Button from 'src/Components/Button/Button';
import { StepperWrapper } from './Style';
import { setCurrentStepProps } from '../Containers/EditionContainer';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../../../Contexts/UserContext';
import { useSelector } from 'react-redux';
import { getMessageError } from '../../../Redux/Configurations/selectors';
import { useEffect } from 'react';

interface StepperProps {
  currentStep: number;
  setCurrentStep: (info: setCurrentStepProps) => void;
  saveAndQuit: () => void;
  steps: any;
}

const Stepper = ({ currentStep, setCurrentStep, saveAndQuit, steps }: StepperProps) => {
  const { t } = useTranslation();
  const handleSetPrevious = () => setCurrentStep('previous');
  const handleSetNext = () => setCurrentStep('next');

  const isFirstStep = currentStep === 0;
  const isLastStep = currentStep === steps.length - 1;

  const { logout } = useUserContext();
  const messageError = useSelector(getMessageError);

  useEffect(() => {
    if (messageError?.endsWith('401')) {
      logout();
    }
  }, [messageError]);

  const renderSteps = () =>
    steps.map(
      (step, index) =>
        !step.disabled && (
          <div
            key={index}
            className={`step ${index < currentStep ? 'done' : ''} ${index == currentStep ? 'active' : ''}`}
          >
            <div className='number'>{index + 1}</div> <div className='label'>{t(step.label)}</div>
          </div>
        )
    );

  return (
    <StepperWrapper>
      <div className='inner-content'>
        <Button
          disabled={isFirstStep}
          label={t('general.previous')}
          onClick={handleSetPrevious}
          className='p-button-text p-button-secondary'
          icon='icon-caret-left'
        />
        <Button label={t('edition.stepper.saveandquit')} onClick={saveAndQuit} className='p-button-secondary' />
      </div>
      <div className='inner-content'>{renderSteps()}</div>
      <div className='inner-content'>
        <Button
          label={isLastStep ? t('general.finish') : t('general.next')}
          icon={isLastStep ? 'icon-check' : 'icon-caret-right'}
          iconPos={isLastStep ? 'left' : 'right'}
          onClick={isLastStep ? saveAndQuit : handleSetNext}
        />
      </div>
    </StepperWrapper>
  );
};

export default Stepper;
