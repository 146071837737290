import { InnerContainer, MainContainer, OidcWarn, PreviewContainer } from './Style';
import { useEffect, useRef, useState } from 'react';

import ResetCss from './ResetCss';
import { WebsiteStepProps } from 'src/Pages/Edition/Containers/Website/WebsiteContainer';
import { getAppLastUpdate } from 'src/Redux/App/selectors';
import { getIframePreviewBundleUrl } from 'src/Tools/Url';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import LocalStorage from '../../Tools/LocalStorage';
import * as Constants from '../../Tools/Constants';

const WithPreview = (WrappedComponent: any) => {
  const ComponentWrapped = (props: WebsiteStepProps) => {
    const { t } = useTranslation();
    const iframeRef = useRef<any>(null);
    const appLastUpdate = useSelector(getAppLastUpdate);
    const [state, setState] = useState('');
    const config = props.watch();

    useEffect(() => {
      setState(
        `<script src='${getIframePreviewBundleUrl()}'></script><script type="text/javascript">DYDU_QUALIFICATION_MODE = true;</script>`
      );
    }, [config, appLastUpdate]);

    const handleAppendStyle = () => {
      LocalStorage.setItem(Constants.DyduStorage.CSS, config.style + ResetCss);
    };

    const renderOidcWarn = () =>
      config?.content?.oidc?.enable && <OidcWarn wide severity='info' text={t('configurations.preview.oidc.warn')} />;

    return (
      <MainContainer>
        <InnerContainer>
          <WrappedComponent {...props} />
        </InnerContainer>
        <PreviewContainer>
          {renderOidcWarn()}
          {/*the name='preview' allows the chatbox to know she has been loaded by channels,*/}
          {/*and then it will look for host and bot (and also other stuffs) not in bot.json but in localstorage*/}
          <iframe name='preview' srcDoc={state} ref={iframeRef} onLoad={handleAppendStyle} />
        </PreviewContainer>
      </MainContainer>
    );
  };

  return ComponentWrapped;
};

export default WithPreview;
