import { formInterface, stepsInterface } from '../../EditionContainer';
import { updateConnectorConfiguration, uploadConnectorAvatar } from 'src/Redux/Configurations/actions/connectorActions';

import Configuration from '../../../Components/Forms/Connector/Teams/Configuration';
import Download from '../../../Components/Forms/Connector/Teams/Download';
import Informations from '../../../Components/Forms/Connector/Global/Informations';
import { readeFileAsBase64 } from 'src/Tools/Files';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getToken } from 'src/Api/Tools/Api';

export interface TeamsStepProps extends formInterface {
  imageUpload: (file: File, avatarType: string, id: string, connectorType: string, index: number) => void;
  downloadZip: () => void;
}

const TeamsContainer = (props: formInterface) => {
  const { currentStep, setStepperConfig, getValues, watch, setValue, handleFormSubmit } = props;
  const dispatch = useDispatch();

  const handleImageUpload = async (
    file: File,
    id: string,
    avatarType: string,
    connectorType: string,
    index: number
  ) => {
    const base64: any = await readeFileAsBase64(file);
    const avatars = getValues('avatars');
    setValue(
      `avatars`,
      avatars.map((avatar, i) => (i === index ? { ...avatar, avatarByte: base64 } : avatar))
    );
    dispatch(uploadConnectorAvatar.request(file, id, avatarType, connectorType));
  };

  const handleDownloadConfig = () =>
    dispatch(updateConnectorConfiguration.request(watch('type'), getValues(), { downloadConfig: true }, getToken()));

  const handleSubmitAndDownload = () => handleFormSubmit(handleDownloadConfig);

  const stepProps: TeamsStepProps = {
    ...props,
    imageUpload: handleImageUpload,
    downloadZip: handleSubmitAndDownload,
  };

  const steps: stepsInterface[] = [
    {
      label: 'edition.informations.title',
      body: <Informations {...stepProps} />,
    },
    {
      label: 'edition.configuration.title',
      body: <Configuration {...stepProps} />,
    },
    { label: 'edition.download.title', body: <Download {...stepProps} /> },
  ];

  useEffect(() => {
    setStepperConfig(steps);
  }, []);

  return <div className='teams-container'>{steps[currentStep]?.body}</div>;
};

export default TeamsContainer;
