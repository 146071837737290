import { Dialog } from 'primereact/dialog';
import Input from '../../../../Components/Input/Input';
import { useState } from 'react';
import Button from '../../../../Components/Button/Button';
import { createRagConfig, getToken } from '../../../../Api/Tools/Api';
import { getCurrentBot } from '../../../../Redux/Bot/selectors';
import { useSelector } from 'react-redux';
import { useRagConfig } from '../../../../Contexts/RagConfigContext';
import { useUserContext } from '../../../../Contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { APP_PATH } from '../../../../Tools/Constants';
import { useTranslation } from 'react-i18next';
import { ModalWrapper, HeaderWrapper } from '../../Style';
import { toastError, toastSuccess } from '../../../../Tools/Toast';

interface ModalCreateRagProps {
  onHide: () => void;
  visible: boolean;
}

const ModalCreateRag = ({ onHide, visible }: ModalCreateRagProps) => {
  const [configName, setConfigName] = useState<string>('');
  const botUUID = useSelector(getCurrentBot)?.botUUID;
  const { ragConfigs, setRagConfigs } = useRagConfig();
  const { logout } = useUserContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const selectedBot = useSelector(getCurrentBot);

  const handleCreateRag = () => {
    const data = {
      name: configName,
      botUUID: botUUID || '',
      botName: selectedBot?.name || '',
    };
    createRagConfig(data, getToken())
      .then((res) => {
        onHide();
        setRagConfigs && setRagConfigs([...(ragConfigs || []), res]);
        toastSuccess(t('externalContents.rag.create.success'));
        navigate(`${APP_PATH.EXTERNAL_CONTENTS}/rag/${res.id}`);
      })
      .catch((error: any) => {
        if (error?.response?.status == '401') {
          logout();
        }
        toastError(t('externalContents.rag.create.error'));
      });
  };

  const isNameValid = configName.length > 0;

  const renderheaderModal = (
    <HeaderWrapper>
      <h1>{t('externalContents.rag.create.titleModal')}</h1>
    </HeaderWrapper>
  );

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      dismissableMask={true}
      style={{ width: '50vw' }}
      header={renderheaderModal}
    >
      <ModalWrapper>
        <Input
          value={configName}
          onChange={(e) => setConfigName(e.target.value)}
          placeholder={t('externalContents.rag.create.placeHolder')}
          maxLength={50}
        />
        <div className={'actions-modal'}>
          <Button label={t('general.create')} onClick={handleCreateRag} disabled={!isNameValid} />
        </div>
      </ModalWrapper>
    </Dialog>
  );
};

export default ModalCreateRag;
