import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import './Assets/Styles/main.scss';

import { ReactElement, useEffect } from 'react';
import { initDaysJs, setDaysLocale } from 'src/Tools/Daysjs';

import { APP } from './Redux/App/types';
import { APP_STATUS, NODE_ENV } from './Tools/Constants';
import Error from './Pages/Error/Error';
import { ErrorBoundary } from 'react-error-boundary';
import Loader from './Components/Loader/Loader';
import ModalContainer from 'src/Components/Modal/ModalContainer';
import Router from './Pages/Router';
import { ToastContainer } from 'react-toastify';
import { getAppStatus } from './Redux/App/selectors';
import { getUserLang } from 'src/Redux/User/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { setEnvironment } from './Redux/App/actions';
import { setAtriaHost } from './Redux/User/actions';
import { getProxy, getServerCode } from './Api/Tools/Api';

const App = (): ReactElement => {
  const appStatus = useSelector(getAppStatus);
  const userLang = useSelector(getUserLang);
  const dispatch = useDispatch();

  const handleSetLocale = () => {
    if (userLang === 'fr') {
      return setDaysLocale(userLang);
    } else {
      return setDaysLocale('en');
    }
  };

  useEffect(() => {
    const environment = process.env.NODE_ENV || NODE_ENV.PRODUCTION;
    const host = getServerCode();
    const proxy = getProxy();
    dispatch(setEnvironment(environment));
    dispatch(setAtriaHost({ host, proxy }));
  }, []);

  useEffect(() => {
    handleSetLocale();
  }, [userLang]);

  useEffect(() => {
    initDaysJs();
  }, []);

  return appStatus === APP_STATUS.REQUEST ? (
    <Loader actions={[APP.INIT.REQUEST]} />
  ) : (
    <ErrorBoundary
      FallbackComponent={({ error, resetErrorBoundary }) => <Error error={error} onClick={resetErrorBoundary} />}
    >
      <ModalContainer />
      <ToastContainer autoClose={3000} />
      <Router />
    </ErrorBoundary>
  );
};

export default App;
