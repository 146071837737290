import { AxiosResponse } from 'axios';
import BaseApi from './BaseApi';
import { getAtriaPath } from '../Tools/Api';

export default class AtriaApi extends BaseApi {
  getPath = () => `${getAtriaPath()}`;

  getBots = (userId: string): Promise<AxiosResponse<API_ATRIA.Schemas.DyduboxBotDTO[]>> =>
    this.axiosClient.get<API_ATRIA.Schemas.DyduboxBotDTO[]>(
      `${this.getPath()}/api/account/dydubox/bots/user?accountUUID=${userId}`
    );

  getBotLanguages = (botUUID: string): Promise<AxiosResponse<API_ATRIA.Schemas.LanguagesDTO[]>> =>
    this.axiosClient.get<API_ATRIA.Schemas.LanguagesDTO[]>(
      `${this.getPath()}/api/account/dydubox/bots/language?botUUID=${botUUID}`
    );

  getConsultationSpaces = (
    botUUID: string,
    defaultLanguage: string
  ): Promise<AxiosResponse<API_ATRIA.Schemas.ConsultationSpaceDTO[]>> =>
    this.axiosClient.get<API_ATRIA.Schemas.ConsultationSpaceDTO[]>(
      `${this.getPath()}/api/account/dydubox/bots/consultationSpace?botUUID=${botUUID}&dataLanguage=${defaultLanguage}`
    );
}
