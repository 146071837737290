/* ----------------------------------------------------------
  APP CONSTANTS
----------------------------------------------------------- */

export const CHATBOX_VERSION = 'feat_RDA-6269_integrer-canaux-et-contenus-externes-dans-des-iframes-du-bms';

export const APP_STATUS = {
  REQUEST: 'init:request',
  SUCCESS: 'init:success',
  FAILURE: 'init:failure',
};

export const NODE_ENV = {
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
};

export const APP_ENV = {
  PREPROD: 'PREPROD',
  PROD: 'PROD',
};

export const APP_PATH = {
  BLANK: '/',
  AUTH: '/auth',
  CONFIGURATIONS: '/configurations',
  EDIT: '/configurations/:type/:id',
  EDIT_RAG: '/external-contents/rag/:id',
  NEW_CONFIG: '/new-configuration',
  NO_BOT: '/no-bot-found',
  EXTERNAL_CONTENTS: '/external-contents',
};

export const APP_URL = {
  fr: {
    doc: 'https://docs.dydu.ai',
    connectorGuide: 'https://docs.dydu.ai/integrations/canaux/connecteurs/teams',
    metaGuide: 'https://docs.dydu.ai/integrations/canaux/connecteurs/meta',
    ragSharepointGuide: 'https://docs.dydu.ai/contenus/contenus-externes#de-renseigner-des-sharepoint',
  },
  en: {
    doc: 'https://docs-en.dydu.ai',
    connectorGuide: 'https://docs-en.dydu.ai/integration/channels/connector/teams',
    metaGuide: 'https://docs-en.dydu.ai/integration/channels/connector/meta',
    ragSharepointGuide: 'https://docs-en.dydu.ai/contents/external-contents#register-sharepoints',
  },
};

/* ----------------------------------------------------------
    METHOD CONSTANTS
----------------------------------------------------------- */

export const METHOD = {
  GET: 'get',
  PUT: 'put',
  POST: 'post',
  DELETE: 'delete',
  PATCH: 'patch',
};

/* ----------------------------------------------------------
  ACTION VERBS
----------------------------------------------------------- */

export const CANCEL = 'CANCEL';
export const CONFIGURATIONS = 'CONFIGURATIONS';
export const CONSULTATION_SPACES = 'CONSULTATION_SPACES';
export const CREATE_ONE = 'CREATE_ONE';
export const DELETE_ONE = 'DELETE_ONE';
export const DOWNLOAD = 'DOWNLOAD';
export const FAILURE = 'FAILURE';
export const GET = 'GET';
export const GET_ALL = 'GET_ALL';
export const GET_ONE = 'GET_ONE';
export const IMPORT_ONE = 'IMPORT_ONE';
export const CHECK = 'CHECK';
export const LANGUAGES = 'LANGUAGES';
export const META = 'META';
export const WEBHOOK = 'WEBHOOK';
export const CREDENTIALS = 'CREDENTIALS';
export const PUBLISH_ONE = 'PUBLISH_ONE';
export const RESTORE_ONE = 'RESTORE_ONE';
export const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
export const UPDATE_NAME = 'UPDATE_NAME';
export const UPDATE_SLUG = 'UPDATE_SLUG';
export const UPDATE_ONE = 'UPDATE_ONE';
export const UPDATE_IMAGES_DATA = 'UPDATE_IMAGES_DATA';
export const UPDATE_CONTENT = 'UPDATE_CONTENT';
export const UPLOAD_EXCEL = 'UPLOAD_EXCEL';
export const UPLOAD_JSON = 'UPLOAD_JSON';
export const UPLOAD_AVATAR = 'UPLOAD_AVATAR';
export const ZIP = 'ZIP';

/* ----------------------------------------------------------
  IFRAME LOCALSTORAGE CONSTANTS
----------------------------------------------------------- */

export const DyduStorage = {
  WIZARD: 'dydu.chatbox.wizard.data.preview',
  MAIN: 'dydu.chatbox.main.preview',
  CSS: 'dydu.chatbox.css.preview',
  IMAGES: 'dydu.chatbox.images.preview',
  BOT_ID: 'dydu.chatbox.botId.preview',
  SERVERS: 'dydu.chatbox.servers.preview',
  LOCALE: 'dydu.chatbox.locale.preview',
  WORDING: 'dydu.chatbox.wording.preview', // Unused ?
  SPACE: 'dydu.chatbox.space.preview',
  BANNER: 'dydu.chatbox.banner.preview',
  ONBOARDING: 'dydu.chatbox.onboarding.preview',
};

export const ATRIA_BOTUUID = 'channels.atria_botuuid';
export const AUTH_PATH = '/#/auth';

/* ----------------------------------------------------------
  CONFIG TYPES
----------------------------------------------------------- */

export const CONFIG_TYPES = {
  WEBSITE: 'website',
  CUSTOMER: 'customer',
  TEAMS: 'teams',
  HANGOUT: 'hangout',
  META: 'meta',
  SLACK: 'slack',
  CALLBOT: 'callbot',
  RAG: 'rag',
};

/* ----------------------------------------------------------
  FORMDATA TYPES
----------------------------------------------------------- */

export const FORMDATA_TYPES = {
  AVATAR: 'avatar',
  FILE: 'file',
  IMAGE: 'image',
  ZIPFILE: 'zipFile',
  DOWNLOAD: 'download',
};

export const AVATAR_TYPE = {
  LOGO: 'LOGO',
  TEASER: 'TEASER',
  COLOR: 'COLOR',
  OUTLINE: 'OUTLINE',
  UNDERSTOOD: 'UNDERSTOOD',
  MISUNDERSTOOD: 'MISUNDERSTOOD',
  LIVECHAT: 'LIVECHAT',
  REWORD: 'REWORD',
  ONBOARDING1: 'ONBOARDING1',
  ONBOARDING2: 'ONBOARDING2',
  ONBOARDING3: 'ONBOARDING3',
};

export const UPLOAD_TYPE = {
  XLSX: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
  ZIP: 'zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed',
  IMAGE: 'image/*',
};
