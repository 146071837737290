import { Auth, Blank, Configurations, Edition, EditionRag, NewConfig, NoBot } from './Pages';
import { Navigate, useRoutes } from 'react-router-dom';

import { APP_PATH } from 'src/Tools/Constants';
import { getNext, getToken } from '../Api/Tools/Api';
import ExternalContents from './ExternalContents/ExternalContents';

export const Router = () => {
  console.log('router authent ' + (getToken() !== '' ? 'yes ' : 'no ') + window.location.href);

  const unprotectedRoutes = useRoutes([
    { path: APP_PATH.AUTH, element: <Auth /> },
    { path: APP_PATH.BLANK, element: <Blank /> },
    { path: '*', element: <Navigate to={APP_PATH.BLANK} /> },
  ]);
  const next = getNext();
  const protectedRoutes = useRoutes([
    { path: APP_PATH.AUTH, element: <Navigate to={next != null ? next : APP_PATH.CONFIGURATIONS} /> },
    { path: APP_PATH.NO_BOT, element: <NoBot /> },
    { path: APP_PATH.EDIT, element: <Edition /> },
    { path: APP_PATH.EDIT_RAG, element: <EditionRag /> },
    { path: APP_PATH.NEW_CONFIG, element: <NewConfig /> },
    { path: APP_PATH.CONFIGURATIONS, element: <Configurations /> },
    { path: APP_PATH.EXTERNAL_CONTENTS, element: <ExternalContents /> },
    { path: '*', element: <Navigate to={next != null ? next : APP_PATH.CONFIGURATIONS} /> },
  ]);

  return getToken() !== '' ? protectedRoutes : unprotectedRoutes;
};

export default Router;
