/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import * as Apis from './apis';

import IApi from './IApi';
import { lowercaseFirstLetter } from '../Tools/Text';

export interface Headers {
  [key: string]: string;
}

let instance: IApi;

const setAuthorization = (authorization: string) => {
  for (const [key] of Object.entries(instance)) {
    if (instance[key].setHeader) {
      instance[key].setHeader('Authorization', authorization);
    }
  }

  return instance;
};

const createInstance = (baseUrl: string, token: string, headers?: Headers): IApi => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const newInstance: IApi = {
    setAuthorization,
  };

  for (const name of Object.keys(Apis)) {
    const apiName = lowercaseFirstLetter(name).replace('Api', '');
    // eslint-disable-next-line import/namespace
    newInstance[apiName] = new (Function.prototype.bind.apply(Apis[name], [null, baseUrl, token, headers]))();
  }

  return newInstance;
};

const Api = {
  getInstance: (baseUrl: string, token: string, headers?: Headers): IApi => {
    if (!instance) {
      instance = createInstance(baseUrl, token, headers);
    }

    return instance;
  },
};

export interface ApiProps extends IApi {}
export default Api;
