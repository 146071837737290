import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import {
  CardTitle,
  CardTitleContainer,
  CreateWrapper,
  CustomLLMCard,
  CustomLLMCardFixedWidth,
  CustomLLMColumn,
  HorizontalGroup,
  LlmDropdownItemTemplate,
  LlmDropdownTemplate,
  LlmTypeDropdown,
  SpecificConfigurationHorizontalGroup,
  Step,
} from '../../Style';
import { APP_PATH } from '../../../../Tools/Constants';
import useDocumentTitle from '../../../../Hooks/useDocumentTitle';
import { Button } from 'primereact/button';
import Input from '../../../../Components/Input/Input';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Skeleton } from 'primereact/skeleton';
import { useCustomLLM } from '../../../../Contexts/CustomLLMContext';
import azureOpenAIDydu from '../../../../Assets/Images/llm/azureopenai_dydu.png';
import azureOpenAI from '../../../../Assets/Images/llm/azureopenai.png';
import openAI from '../../../../Assets/Images/llm/openai.png';
import mistralAI from '../../../../Assets/Images/llm/mistralai.png';
import googleAIGemini from '../../../../Assets/Images/llm/googleai_gemini.png';
import { setNext } from '../../../../Api/Tools/Api';

const CustomLLM = () => {
  setNext(APP_PATH.EXTERNAL_CONTENTS);
  const { t } = useTranslation();
  const {
    customLLM,
    defaultCustomLLM,
    defaultLLMType,
    updateCustomLLM,
    removeCustomLLM,
    getLlmTypeConfig,
    availableLLMs,
    loading,
  } = useCustomLLM();

  const [selectedLlmTypeConfig, setSelectedLlmTypeConfig] = useState<
    API_BACKEND.Schemas.AvailableLLMResponse | undefined
  >(getLlmTypeConfig(customLLM?.llmType) || defaultLLMType);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [llmType, setLlmType] = useState<
    'DEFAULT_GPT_4O_AZURE_OPENAI' | 'OPENAI' | 'AZURE_OPENAI' | 'MISTRAL' | 'GOOGLEAI_GEMINI'
  >(defaultLLMType?.name || 'DEFAULT_GPT_4O_AZURE_OPENAI');
  const [apiKey, setApiKey] = useState<string>('');
  const [model, setModel] = useState<string>('');
  const [embedModel, setEmbedModel] = useState<string>('');

  const [specificConfiguration, setSpecificConfiguration] =
    useState<API_BACKEND.Schemas.LLMCustomBotConfigurationSpecificConfiguration>();

  useDocumentTitle(`${t('general.pages.externalContents')}`);

  const resetForm = () => {
    setIsEditing(false);
    setLlmType(customLLM?.llmType || defaultLLMType?.name || 'DEFAULT_GPT_4O_AZURE_OPENAI');
    setApiKey(customLLM?.apiKey || '');
    setModel(customLLM?.model || '');
    setEmbedModel(customLLM?.embedModel || '');
    setSpecificConfiguration(customLLM?.specificConfiguration || {});
    setSelectedLlmTypeConfig(getLlmTypeConfig(customLLM?.llmType));
  };

  const submitForm = () => {
    updateCustomLLM(llmType, apiKey, model, embedModel, specificConfiguration);
  };

  /**
   * Called when config is loaded, saved or restored
   */
  useEffect(() => {
    resetForm();
  }, [customLLM]);

  const getImageFromLlmType = (
    llmType: 'DEFAULT_GPT_4O_AZURE_OPENAI' | 'OPENAI' | 'AZURE_OPENAI' | 'MISTRAL' | 'GOOGLEAI_GEMINI'
  ) => {
    switch (llmType) {
      case 'DEFAULT_GPT_4O_AZURE_OPENAI':
        return azureOpenAIDydu;
      case 'AZURE_OPENAI':
        return azureOpenAI;
      case 'OPENAI':
        return openAI;
      case 'MISTRAL':
        return mistralAI;
      case 'GOOGLEAI_GEMINI':
        return googleAIGemini;
      default:
        return azureOpenAIDydu;
    }
  };

  const getAltImageFromLlmType = (
    llmType: 'DEFAULT_GPT_4O_AZURE_OPENAI' | 'OPENAI' | 'AZURE_OPENAI' | 'MISTRAL' | 'GOOGLEAI_GEMINI'
  ) => {
    switch (llmType) {
      case 'DEFAULT_GPT_4O_AZURE_OPENAI':
        return t('externalContents.customLLM.type.azureOpenAIDydu');
      case 'AZURE_OPENAI':
        return t('externalContents.customLLM.type.azureOpenAI');
      case 'OPENAI':
        return t('externalContents.customLLM.type.openAI');
      case 'MISTRAL':
        return t('externalContents.customLLM.type.mistralAI');
      case 'GOOGLEAI_GEMINI':
        return t('externalContents.customLLM.type.googleAIGemini');
      default:
        return t('externalContents.customLLM.type.azureOpenAIDydu');
    }
  };

  const llmTypeTemplate = (
    option: 'DEFAULT_GPT_4O_AZURE_OPENAI' | 'OPENAI' | 'AZURE_OPENAI' | 'MISTRAL' | 'GOOGLEAI_GEMINI'
  ) => {
    const image = getImageFromLlmType(option);
    const alt = getAltImageFromLlmType(option);
    if (image) {
      return <LlmDropdownTemplate src={image} alt={alt} />;
    }
    return option;
  };

  const llmTypeItemTemplate = (
    option: 'DEFAULT_GPT_4O_AZURE_OPENAI' | 'OPENAI' | 'AZURE_OPENAI' | 'MISTRAL' | 'GOOGLEAI_GEMINI'
  ) => {
    const image = getImageFromLlmType(option);
    const alt = getAltImageFromLlmType(option);
    if (image) {
      return <LlmDropdownItemTemplate src={image} alt={alt} />;
    }
    return option;
  };

  const renderSpecificConfiguration = () => {
    if (llmType === 'OPENAI') {
      return renderSpecificConfigurationOpenAI();
    }

    if (llmType === 'AZURE_OPENAI' || llmType === 'DEFAULT_GPT_4O_AZURE_OPENAI') {
      return renderSpecificConfigurationAzureOpenAI();
    }

    return <p className={'no-option'}>{t('externalContents.customLLM.specificConfiguration.notConcerned')}</p>;
  };

  const renderSpecificConfigurationOpenAI = () => {
    return (
      <SpecificConfigurationHorizontalGroup>
        <label htmlFor='apiVersion'>{t('externalContents.customLLM.specificConfiguration.openAI.apiVersion')} :</label>
        <Input
          className={'input-specific-configuration-line'}
          value={specificConfiguration?.apiVersion}
          onChange={(e) =>
            setSpecificConfiguration({
              ...specificConfiguration,
              apiVersion: e.target.value,
            })
          }
          disabled={!isEditing || !selectedLlmTypeConfig?.isCustomizable}
        />
      </SpecificConfigurationHorizontalGroup>
    );
  };

  const renderSpecificConfigurationAzureOpenAI = () => {
    return (
      <>
        <SpecificConfigurationHorizontalGroup>
          <label htmlFor='apiVersion'>
            {t('externalContents.customLLM.specificConfiguration.openAI.apiVersion')} :
          </label>
          <Input
            className={'input-specific-configuration-line'}
            value={specificConfiguration?.apiVersion}
            onChange={(e) =>
              setSpecificConfiguration({
                ...specificConfiguration,
                apiVersion: e.target.value,
              })
            }
            disabled={!isEditing || !selectedLlmTypeConfig?.isCustomizable}
            unstyled={!isEditing}
          />
        </SpecificConfigurationHorizontalGroup>
        <SpecificConfigurationHorizontalGroup>
          <label htmlFor='azureEndpoint'>
            {t('externalContents.customLLM.specificConfiguration.azureOpenAI.endpoint')} :
          </label>
          <Input
            className={'input-specific-configuration-line'}
            value={specificConfiguration?.azureEndpoint}
            onChange={(e) =>
              setSpecificConfiguration({
                ...specificConfiguration,
                azureEndpoint: e.target.value,
              })
            }
            disabled={!isEditing || !selectedLlmTypeConfig?.isCustomizable}
            unstyled={!isEditing}
          />
        </SpecificConfigurationHorizontalGroup>
        <SpecificConfigurationHorizontalGroup>
          <label htmlFor='azureDeployment'>
            {t('externalContents.customLLM.specificConfiguration.azureOpenAI.deploymentName')} :
          </label>
          <Input
            className={'input-specific-configuration-line'}
            value={specificConfiguration?.azureDeployment}
            onChange={(e) =>
              setSpecificConfiguration({
                ...specificConfiguration,
                azureDeployment: e.target.value,
              })
            }
            disabled={!isEditing || !selectedLlmTypeConfig?.isCustomizable}
            unstyled={!isEditing}
          />
        </SpecificConfigurationHorizontalGroup>
      </>
    );
  };

  const getAvailableLLMsOptions = () => {
    return availableLLMs.map((availableLLM) => availableLLM.name);
  };

  return (
    <>
      {loading ? (
        <Skeleton width='1780px' height='390.5px' className='mb-2' borderRadius='16px'></Skeleton>
      ) : (
        <form>
          <CreateWrapper>
            <div className='container-wrapper'>
              <CustomLLMColumn>
                <CardTitleContainer>
                  <CardTitle>{t('externalContents.customLLM.title')}</CardTitle>
                  {!loading && isEditing ? (
                    <div>
                      <Button
                        label={t('general.cancel')}
                        icon='icon-eraser'
                        onClick={(e) => {
                          e.preventDefault();
                          resetForm();
                        }}
                        className='mr-2 p-button-text p-button-secondary'
                      />
                      {selectedLlmTypeConfig?.isDefault ? (
                        <Button
                          label={t('externalContents.customLLM.action.restore')}
                          icon='icon-history'
                          onClick={(e) => {
                            e.preventDefault();
                            removeCustomLLM();
                          }}
                        />
                      ) : (
                        <Button
                          label={t('general.apply')}
                          icon='icon-check'
                          disabled={!selectedLlmTypeConfig?.isCustomizable}
                          onClick={(e) => {
                            e.preventDefault();
                            submitForm();
                          }}
                        />
                      )}
                    </div>
                  ) : (
                    <Button label={t('general.modify')} icon='icon-pencil' onClick={() => setIsEditing(true)} />
                  )}
                </CardTitleContainer>
                <HorizontalGroup>
                  <CustomLLMColumn>
                    <HorizontalGroup className='width-30-percent'>
                      <CustomLLMCardFixedWidth title={t('externalContents.customLLM.modelType')} $width={'40%'}>
                        {isEditing ? <Step>1</Step> : null}
                        <LlmTypeDropdown
                          value={llmType}
                          onChange={(e) => {
                            setLlmType(e.value);
                            const llmTypeConfig = getLlmTypeConfig(e.value);
                            setSelectedLlmTypeConfig(llmTypeConfig);

                            if (llmTypeConfig?.name === 'DEFAULT_GPT_4O_AZURE_OPENAI') {
                              setApiKey(defaultCustomLLM?.apiKey || '');
                              setModel(defaultCustomLLM?.model || '');
                              setEmbedModel(defaultCustomLLM?.embedModel || '');
                              setSpecificConfiguration(defaultCustomLLM?.specificConfiguration);
                            } else {
                              setSpecificConfiguration({});
                            }
                          }}
                          disabled={!isEditing}
                          options={getAvailableLLMsOptions()}
                          valueTemplate={llmTypeTemplate}
                          itemTemplate={llmTypeItemTemplate}
                          className='w-full  md:w-14rem'
                        />
                      </CustomLLMCardFixedWidth>
                      <CustomLLMCard title={t('externalContents.customLLM.authentication')}>
                        {isEditing ? <Step>2</Step> : null}
                        <Input
                          className={'input-specific-configuration-line'}
                          value={apiKey}
                          onChange={(e) => setApiKey(e.target.value)}
                          disabled={!isEditing || !selectedLlmTypeConfig?.isCustomizable}
                          unstyled={!isEditing}
                        />
                      </CustomLLMCard>
                    </HorizontalGroup>
                    <CustomLLMCard title={t('externalContents.customLLM.models')}>
                      {isEditing ? <Step>3</Step> : null}
                      <HorizontalGroup>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '100%',
                          }}
                        >
                          <Input
                            className={'input-specific-configuration-line'}
                            value={model}
                            onChange={(e) => setModel(e.target.value)}
                            disabled={!isEditing || !selectedLlmTypeConfig?.isCustomizable}
                            unstyled={!isEditing}
                          />
                          <Input
                            className={'input-specific-configuration-line'}
                            value={embedModel}
                            onChange={(e) => setEmbedModel(e.target.value)}
                            disabled={!isEditing || !selectedLlmTypeConfig?.isCustomizable}
                            unstyled={!isEditing}
                          />
                        </div>
                      </HorizontalGroup>
                    </CustomLLMCard>
                  </CustomLLMColumn>
                  <CustomLLMColumn>
                    <CustomLLMCard title={t('externalContents.customLLM.specificConfiguration.title')}>
                      {isEditing ? <Step>4</Step> : null}
                      <ScrollPanel>{renderSpecificConfiguration()}</ScrollPanel>
                    </CustomLLMCard>
                  </CustomLLMColumn>
                </HorizontalGroup>
              </CustomLLMColumn>
            </div>
          </CreateWrapper>
        </form>
      )}
    </>
  );
};

export default CustomLLM;
