import { AVATAR_TYPE, UPLOAD_TYPE } from 'src/Tools/Constants';

import ColorPicker from 'src/Components/ColorPicker/ColorPicker';
import { ColorPlaceholder } from 'src/Components/ColorPicker/Style';
import { DisplayProps } from '../Display';
import Divider from 'src/Components/Divider/Divider';
import Heading from 'src/Components/Heading/Heading';
import ImageUpload from 'src/Components/ImageUpload/ImageUpload';
import { InnerContent } from 'src/Pages/Edition/Style';
import { SubField } from 'src/Components/Style';
import { useTranslation } from 'react-i18next';

interface AppearanceProps extends DisplayProps {}

const Appearance = ({ watch, imageUpload, setValue, imagesData }: AppearanceProps) => {
  const { t } = useTranslation();

  const mainColors = ['#41479B', '#2DB0FF', '#F3B63B', '#2CBF87'];

  const handleImageUpload = (file: File, type: string, field: string) => imageUpload(file, type, field);

  const handleChangeColor = (color: string) => {
    setValue('theme.palette.primary.main', color);
  };

  const imageList = [
    {
      title: t('edition.display.images.logo'),
      src: imagesData?.logo,
      type: AVATAR_TYPE.LOGO,
      field: 'imagesData.logo',
    },
    {
      title: t('edition.display.images.teaser'),
      src: imagesData?.teaser,
      type: AVATAR_TYPE.TEASER,
      field: 'imagesData.logo',
    },
  ];

  const renderImagesUpload = () =>
    imageList.map((image, index) => (
      <div key={image.type}>
        <ImageUpload
          key={image.type}
          src={image.src}
          title={image.title}
          size='large'
          onUpload={(file) => handleImageUpload(file, image.type, image.field)}
          accept={UPLOAD_TYPE.IMAGE}
        />
        {index !== imageList.length - 1 && <Divider />}
      </div>
    ));

  const renderColorPlaceholders = () =>
    mainColors?.map((color) => (
      <ColorPlaceholder
        key={color}
        className='color-placeholder'
        onClick={() => handleChangeColor(color)}
        selected={color === watch('theme.palette.primary.main')}
        bgColor={color}
      />
    ));

  return (
    <InnerContent className='appearance'>
      <div className='main-color'>
        <Heading as='h5' title={t('edition.display.title.mainColor')} />
        <div className='inner-content'>
          {renderColorPlaceholders()}
          <ColorPicker
            defaultColor={mainColors[0]}
            color={watch('theme.palette.primary.main')}
            hexField
            onChange={handleChangeColor}
          />
        </div>
      </div>
      <div>
        <Divider layout='horizontal' />
      </div>
      <SubField>
        <div className='main-logo'>{renderImagesUpload()}</div>
      </SubField>
    </InnerContent>
  );
};

export default Appearance;
