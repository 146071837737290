import axios, { AxiosInstance } from 'axios';

import { Headers } from '../';
import Qs from 'qs';
import { getServerCode } from '../Tools/Api';

export default abstract class BaseApi {
  protected axiosClient: AxiosInstance;
  protected secureAxiosClient: AxiosInstance;

  constructor(baseUrl: string, token: string, headers: Headers = {}) {
    const baseHeaders = {
      Accept: '*/*',
      'Content-Type': 'application/json',
    };

    this.axiosClient = axios.create();

    this.secureAxiosClient = axios.create({
      baseURL: baseUrl,
      headers: {
        ...baseHeaders,
        ...headers,
      },
      paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true }),
    });

    this.secureAxiosClient.interceptors.request.use(
      function (config) {
        if (token && config.headers) {
          config.headers.Authorization = `Bearer ${token}`;
          config.headers.servercode = `${getServerCode()}`;
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    this.secureAxiosClient.interceptors.response.use(
      (response) => response,
      async (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
      }
    );
  }

  setHeader = (name: string, value: string) => {
    const { headers } = this.secureAxiosClient.defaults;
    headers[name] = value;

    return this;
  };

  getBaseUrl = () => {
    return this.secureAxiosClient.defaults.baseURL;
  };
}
