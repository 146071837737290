import * as Actions from './actions/configurationsActions';
import * as ActionsWebsite from './actions/websiteActions';
import * as ActionsConnector from './actions/connectorActions';
import * as Types from './types';

import { all, call, put, select, spawn, takeLatest } from 'typed-redux-saga';

import connectorSagas from './sagas/connectorSagas';
import { getAtriaHost } from 'src/Redux/User/selectors';
import { getClientApi } from 'src/Api/Tools/Api';
import { toastError } from 'src/Tools/Toast';
import websiteSaga from './sagas/websiteSagas';
import i18n from '../../Services/i18n';
import { ApiProps } from '../../Api';

/**
 * API CALLS
 */

/**
 * Get Connector configurations list
 * @param botData
 * @returns
 */
function* getAllConfigurations({ payload: { botData } }) {
  const Client: ApiProps = yield call(getClientApi);
  const host = yield select(getAtriaHost);
  const connectors: any[] = [];
  const websites: any[] = [];
  let getAllConfigurationsError: any = null;

  try {
    const { data: connector } = yield* call(Client.connector.getConnectorConfigurationsList, botData.botUUID);
    connectors.push(...connector);
  } catch (error) {
    yield put(ActionsConnector.getConnectorConfigurationsList.failure(error));
    toastError(i18n.t('configurations.connector.list_load_error'));
    getAllConfigurationsError = error;
  }

  try {
    const { data: website } = yield* call(Client.backend.getWebsiteConfigurationsList, botData.botUUID, host);
    websites.push(...website);
  } catch (error) {
    yield put(ActionsWebsite.getWebsiteConfigurationsList.failure(error));
    toastError(i18n.t('configurations.website.list_load_error'));
    getAllConfigurationsError = error;
  }

  getAllConfigurationsError == null
    ? yield put(Actions.getAllConfigurations.success([...connectors, ...websites]))
    : yield put(Actions.getAllConfigurations.failure(getAllConfigurationsError));
}

/**
 * Configurations Saga
 */
export default function* configurationsSaga() {
  try {
    yield all([
      /** API Calls */
      takeLatest(Types.CONFIGURATIONS.GET_ALL.REQUEST, getAllConfigurations),
      /** Config Types Sagas */
      spawn(websiteSaga),
      spawn(connectorSagas),
    ]);
  } catch (error) {
    toastError(error);
  }
}
